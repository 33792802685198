import React from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import { Card, CardBody, Col, Row } from "reactstrap"

const Overview = props => {
  const { license } = props

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <h5 className="fw-semibold">{license?.grn}</h5>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <h5 className="fw-semibold">Customer Profile</h5>

              <div className="table-responsive">
                <table className="table">
                  <tbody>
                    <tr>
                      <th scope="col">First Name</th>
                      <td scope="col">{license?.customerProfile?.firstName}</td>
                    </tr>
                    <tr>
                      <th scope="col"> Last Name</th>
                      <td scope="col">{license?.customerProfile?.lastName}</td>
                    </tr>
                    <tr>
                      <th scope="row">BirthDate</th>
                      <td scope="col">{license?.customerProfile?.birthDate}</td>
                    </tr>
                    <tr>
                      <th scope="row">Email Address</th>
                      <td scope="col">
                        {license?.customerProfile?.emailAddress}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Mobile Number</th>
                      <td scope="col">{license?.mobileNumber}</td>
                    </tr>
                    <tr>
                      <th scope="row">License Number</th>
                      <td scope="col">
                        {license?.customerProfile?.drivingLicenseNumber}
                      </td>
                    </tr>
                    {/* <tr>
                                        <th scope="row">License Issue</th>
                                        <td scope="col">{license?.customerProfile?.drivingLicenseIssueDate}</td>
                                    </tr>
                                    <tr>
                                    <th scope="row">License Expiry </th>
                                    <td scope="col">{license?.customerProfile?.drivingLicenseExpiryDate}</td>
                                    </tr> */}
                    <tr>
                      <th scope="row">License Class </th>
                      <td scope="col">
                        {license?.customerProfile?.drivingLicenseClass}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Nationality</th>
                      <td scope="col">
                        {license?.customerProfile?.drivingLicenseNationality}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Place of Birth</th>
                      <td scope="col">
                        {license?.customerProfile?.drivingLicensePlaceOfBirth}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Address</th>
                      <td scope="col">
                        {license?.customerProfile?.drivingLicenseAddress}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <CardBody>
              <a href={license?.customerProfile?.customerPhotoUrl}>
                <p className="fw-semibold">
                  <i className="bx bx-user-circle bx-lg" />
                  <br></br>
                  Photo{" "}
                </p>
              </a>
            </CardBody>
          </Card>
        </Col>

        <Col>
          <Card>
            <CardBody>
              <a href={license?.customerProfile?.nationalDrivingLicenseUrl}>
                <p className="fw-semibold">
                  <i className="bx bx-id-card bx-lg" />
                  <br></br>
                  Driving License
                </p>
              </a>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardBody>
              <a href={license?.invoiceUrl}>
                <p className="fw-semibold">
                  <i className="bx bx-file bx-lg" />
                  <br></br>
                  Invoice{" "}
                </p>
              </a>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* <Row>
      <Col xl={6}>
          <Card>
            <CardBody>
            <a href={license?.invoiceUrl}>
              <h5 className="fw-semibold">
                
                <i className="bx bx-file bx-lg"/><br></br>
                Invoice Url</h5></a>
            </CardBody>
          </Card>
        </Col>

        <Col >
          <Card>
            <CardBody>
              <h5 className="fw-semibold">
                
              </h5>
            </CardBody>
          </Card>
        </Col>

       
       
      </Row> */}
    </React.Fragment>
  )
}

Overview.propTypes = {}

export default Overview
