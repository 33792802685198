import React from "react"
import dateFormat from "../../common/helpers/dateFormat"
import { Row, Col } from "reactstrap"

const CustomerId = cell => {
  return cell.value ? cell.value : ""
}

const TravelAgencyName = cell => {
  return cell.value ? cell.value : ""
}
const LastNotified = cell => {
  return cell.value ? (
    <span className="text-muted">{dateFormat(cell.value)}</span>
  ) : (
    ""
  )
}

const Emails = cell => {
  return Array.isArray(cell.value) ? (
    <span>
      {cell.value.map((value, index) => {
        return (
          <Row key={index} className="mx-1">
            {value}
          </Row>
        )
      })}
    </span>
  ) : (
    ""
  )
}

export { CustomerId, TravelAgencyName, LastNotified, Emails }
