import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Container, Row } from "reactstrap"
import {
  getVoucherLimitListById,
  setVoucherLimit,
  updateVoucherLimit,
  getLicenseRequestSummary,
  deleteVoucherLimit,
  resetState,
} from "store/actions"
import VoucherLimit from "./VoucherLimit"
import LoadingSpinner from "common/helpers/loadingSpinner"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import authDetails from "../../helpers/auth-details/auth-details"

const VoucherLimitList = props => {
  const {
    onGetVoucherLimitListById,
    voucherLimitList,
    onSetVoucherLimit,
    onUpdateVoucherLimit,
    onGetLicenseRequestSummary,
    onDeleteVoucherLimit,
    onResetState,
    loading,
    isAddSuccess,
    isUpdateSuccess,
    isDeleteSuccess,
    error,
    summary,
  } = props

  const [user, setUser] = useState(authDetails)
  const [filter, setFilter] = useState( {
    Field: "customer",
    Id: user.customerID,
  })
  const [voucherLimits, setVoucherLimits] = useState()
  const [liceseRequestSummary, setLiceseRequestSummary] = useState()
  const [loader, setLoader] = useState()
  const [err, setErr] = useState()

  
  toastr.options = {
    positionClass: "toast-top-right",
    timeOut: 5000,
    extendedTimeOut: 1000,
    closeButton: true,
    debug: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 1000,
  }

  function showToastSuccess(message) {
    toastr.success(`Successfuly ${message}`, "")
    onResetState()
    handleReload()
  }

  function showToastError() {
    toastr.error(`${message} , Something went wrong`, "")
    onResetState()
    handleReload()
  }



  useEffect(() => {
   
    onGetVoucherLimitListById(filter)
    onGetLicenseRequestSummary(filter.Id)
  }, [])

  useEffect(() => {
   
    setVoucherLimits(voucherLimitList)
  }, [voucherLimitList])

  useEffect(() => {
    setLiceseRequestSummary(summary)
  }, [summary])

  useEffect(() => {
    setLoader(loading)
  }, [loading])

  useEffect(() => {
  
    setErr(error)
  }, [error])

  const handleReload = async () => {
    await sleep(1000);
    window.location.reload();
  };

  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));




  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>

            {isUpdateSuccess && showToastSuccess("Updated") }
            {isUpdateSuccess === false && showToastError("Not Updated")}

            {isAddSuccess && showToastSuccess("Added") }
            {isAddSuccess === false && showToastError("Not Added")}

            {isDeleteSuccess && showToastSuccess("Deleted") }
            {isDeleteSuccess === false && showToastError("Not Deleted")}

            {loader ? (
              <LoadingSpinner />
            ) : (
              <>
                <Row>
                  {voucherLimits && (
                    <VoucherLimit
                     user={user}
                      voucherLimits={voucherLimits}
                      liceseRequestSummary={liceseRequestSummary}
                      onSetVoucherLimit={onSetVoucherLimit}
                      onGetVoucherLimitListById={onGetVoucherLimitListById}
                      onUpdateVoucherLimit={onUpdateVoucherLimit}
                      onDeleteVoucherLimit={onDeleteVoucherLimit}
                      onResetState={onResetState}
                    />
                  )}
                </Row>
              </>
            )}
          </Container>
        </div>
      </React.Fragment>
    </>
  )
}

VoucherLimitList.propTypes = {}

const mapStateToProps = state => (

  {
    voucherLimitList: state.VoucherLimit.voucherLimitList,
    loading: state.VoucherLimit.loading,
    isUpdateSuccess: state.VoucherLimit.updateSuccess,
    isAddSuccess: state.VoucherLimit.addSuccess,
    isDeleteSuccess: state.VoucherLimit.deleteSuccess,
    error: state.VoucherLimit.error,
    summary: state.LicenseRequest.summary,
  }
)

const mapDispatchToProps = dispatch => ({
  onGetVoucherLimitListById: filter => dispatch(getVoucherLimitListById(filter)),
  onSetVoucherLimit: data => dispatch(setVoucherLimit(data)),
  onUpdateVoucherLimit: data => dispatch(updateVoucherLimit(data)),
  onDeleteVoucherLimit: data => dispatch(deleteVoucherLimit(data)),
  onGetLicenseRequestSummary: customerId =>dispatch(getLicenseRequestSummary(customerId)),
  onResetState: () =>dispatch(resetState()),
})

export default connect(mapStateToProps, mapDispatchToProps)(VoucherLimitList)
