import { call, put, takeEvery, takeLatest } from "redux-saga/effects"


import {
  GET_CUSTOMER_LIST,
  GET_OFFICE_LIST_BY_ID,
} from "./actionTypes"

import {
  getCustomerListSuccess,
  getCustomerListFail,
  getOfficeListByIdSuccess,
  getOfficeListByIdFail,
  returnStateTransaction,

} from "./actions"

//Include Both Helper File with needed methods
import {
 getCustomerList,
 getOfficeListById,

} from "helpers/fakebackend_helper"


function* _getCustomerList() {
  try {
    const response = yield call(getCustomerList)
    yield put(getCustomerListSuccess(response.data))
  } catch (error) {
    yield put(getCustomerListFail(error?.message))
  }
}


function* _getOfficeListById( {customerId} ) {
  try {

    const response = yield call(getOfficeListById, customerId)
    yield put(getOfficeListByIdSuccess(response.data))
  } catch (error) {
    yield put(getOfficeListByIdFail(error))
  }
}

function* CustomerSaga() {

  yield takeEvery(GET_CUSTOMER_LIST, _getCustomerList)
  yield takeEvery(GET_OFFICE_LIST_BY_ID, _getOfficeListById)
}

export default CustomerSaga
