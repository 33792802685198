import StackedColumnChart from "components/Charts/StackedColumnChart"
import React, { useEffect, useMemo, useState } from "react"
import { Card, Row, CardBody, CardTitle, Col, Label, Button } from "reactstrap"
import Barchart from "components/Charts/barchart"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import {
  getDashboardData as onGetDashboardData,
  getTopTravelAgencyData as onGetTopTravelAgencyData,
} from "store/actions"
import { isEmpty } from "lodash"
import TableContainer from "../TableContainers/TableContainer"
import TableContainerFilters from "../TableContainers/TableContainerFilters"
import DateToday from "./DateToday"
import { currentYear } from "common/helpers/dateFormat"
// import dummy_data from "./data"

const AdminView = ({ periodType, selectedYear }) => {
  const [selectedCustomerId, setSelectedCustomerId] = useState(null)
  const [customerIdOptions, setCustomerIdOptions] = useState([])
  const [selectedMonth, setSelectedMonth] = useState(DateToday())

  const columns = useMemo(
    () => [
      {
        Header: "Customer Id",
        accessor: "customerId",
        disableFilters: true,
      },
      {
        Header: "Travel Agency",
        accessor: "travelAgency",
        disableFilters: true,
      },
      {
        Header: "Office Id",
        accessor: "officeId",
        disableFilters: true,
      },
      {
        Header: "Transactions",
        accessor: "count",
        disableFilters: true,
      },
      {
        Header: "Sales",
        accessor: "revenue",
        disableFilters: true,
      },

      {
        Header: "Month",
        accessor: "month",
        disableFilters: true,
      },
    ],
    []
  )

  const columnsTop10 = useMemo(
    () => [
      {
        Header: "Customer Id",
        accessor: "customerId",
        disableFilters: true,
      },
      {
        Header: "Travel Agency",
        accessor: "travelAgency",
        disableFilters: true,
      },
      {
        Header: "Transactions",
        accessor: "count",
        disableFilters: true,
      },
      {
        Header: "Sales",
        accessor: "revenue",
        disableFilters: true,
      },
    ],
    []
  )

  const dispatch = useDispatch()
  const { stateDashboardData, stateTopTravelAgencyData } = useSelector(
    state => ({
      stateDashboardData: state.Dashboard.dashboardData.data,
      stateTopTravelAgencyData: state.Dashboard.topTravelAgencyData.data,
    })
  )

  //REVENUE or COUNT
  const [chartView, setChartView] = useState({
    label: "Sales",
    value: 0,
  })

  const [periodSelectedValue, setPeriodSelectedValue] = useState({
    label: "Transactions",
    value: 0,
  })

  //DROPDOWN VALUE
  const selectOptions = [
    {
      options: [
        { label: "Sales", value: 0 },
        { label: "Transactions", value: 1 },
      ],
    },
  ]
  const periodOption = [
    {
      options: [
        { label: "Monthly", value: 0 },
        { label: "Yearly", value: 1 },
      ],
    },
  ]

  const [dashboardData, setDashboardData] = useState(null)
  const [topTravelAgencyData, setTopTravelAgencyData] = useState([])
  const [displayedData, setDisplayedData] = useState([])
  const [displayedCategory, setDisplayedCategory] = useState(null)

  useEffect(() => {
    dispatch(onGetDashboardData(periodType, null, selectedYear))
    dispatch(onGetTopTravelAgencyData(DateToday().value, selectedYear))
  }, [dispatch, selectedYear])

  useEffect(() => {
    if (stateDashboardData !== undefined) {
      setDashboardData(stateDashboardData)
      setDisplayedData(stateDashboardData.revenues)
      setDisplayedCategory(stateDashboardData.categories)
      setCustomerIdOptions(stateDashboardData.optionGroup)
    }
  }, [stateDashboardData])

  useEffect(() => {
    if (stateTopTravelAgencyData !== undefined) {
      setTopTravelAgencyData(stateTopTravelAgencyData)
    }
  }, [stateTopTravelAgencyData])

  useEffect(() => {
    dispatch(onGetTopTravelAgencyData(selectedMonth.value, selectedYear))
  }, [selectedMonth, selectedYear])

  useEffect(() => {}, [dashboardData])

  useEffect(() => {}, [topTravelAgencyData])

  function onChangeData(item) {
    setChartView(item)
    if (item.value === 0) {
      setDisplayedData(dashboardData.revenues)
    } else {
      setDisplayedData(dashboardData.productivities)
    }
    setDisplayedCategory(dashboardData.categories)
  }

  const onChangeFilter = () => {
    dispatch(onGetDashboardData(periodType, selectedCustomerId, selectedYear))
  }

  return (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <div className="d-flex mb-2 justify-content-between">
              <div>
                <CardTitle>
                  Monthly Summary of Entire Market ({selectedYear})
                </CardTitle>
              </div>
              <div className="d-flex justify-content-end">
                <Select
                  value={chartView}
                  onChange={onChangeData}
                  options={selectOptions}
                  className="select2-selection mr-2"
                />
              </div>
            </div>

            <StackedColumnChart
              periodData={displayedData}
              isLoading={false}
              dataColors='["--bs-primary","--bs-secondary","--bs-success","--bs-blue", "--bs-indigo", "--bs-purple","--bs-pink","--bs-red","--bs-orange","--bs-yellow","--bs-green","--bs-teal","--bs-cyan","--bs-gray","--bs-gray-dark","--bs-info","--bs-warning","--bs-danger"]'
            />
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <Row>
              <Col lg={10}>
                <Select
                  value={selectedCustomerId}
                  isMulti={true}
                  onChange={value => setSelectedCustomerId(() => [...value])}
                  options={customerIdOptions}
                  className="select2-selection"
                />
              </Col>
              <Col>
                <Button
                  color="primary"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => {
                    onChangeFilter()
                  }}
                >
                  Apply Filter
                </Button>
              </Col>
            </Row>
            {!isEmpty(dashboardData?.data) && !isEmpty(columns) && (
              <TableContainerFilters
                columns={columns}
                data={dashboardData?.data}
                customPageSize={7}
                isGlobalFilter={true}
              />
            )}
          </CardBody>
        </Card>
      </Col>

      <Col>
        <Card>
          <CardBody>
            <div className="d-flex">
              <CardTitle>Top 10 Agencies of the Month </CardTitle>
              <div className="ms-auto">
                <Select
                  value={selectedMonth}
                  onChange={value => setSelectedMonth(() => value)}
                  options={dashboardData?.monthOptionGroup.sort(
                    (a, b) => b.value - a.value
                  )}
                  className="select2-selection"
                />
              </div>
            </div>
            <Barchart
              data={topTravelAgencyData?.data}
              isLoading={false}
              plotOptions={{
                bar: {
                  horizontal: !1,
                  endingShape: "rounded",
                  dataLabels: {
                    position: "top", // top, center, bottom
                  },
                },
              }}
              dataLabels={{
                enabled: true,
                formatter: function (val) {
                  return val
                },
                offsetY: -20,
                style: {
                  fontSize: "12px",
                  colors: ["#304758"],
                },
              }}
              categories={[""]}
              dataColors='["--bs-primary","--bs-secondary","--bs-success","--bs-blue", "--bs-indigo", "--bs-purple","--bs-pink","--bs-red","--bs-orange","--bs-yellow","--bs-green","--bs-teal","--bs-cyan","--bs-gray","--bs-gray-dark","--bs-info","--bs-warning","--bs-danger"]'
            />
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            {!isEmpty(topTravelAgencyData?.tableData) && !isEmpty(columns) && (
              <TableContainer
                columns={columnsTop10}
                data={topTravelAgencyData?.tableData}
                customPageSize={10}
                isGlobalFilter={true}
              />
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default AdminView
