import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

import LicenseRequestList from "../pages/LicenseRequestList/index"

import LicenseRequestDetail from "../pages/LicenseRequestDetail/index"
import Pages404 from "components/pages-404"

import VoucherLimitList from "../pages/VoucherLimitList/index"

//Notification
import NotificationList from "../pages/NotificationList/index"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/profile", component: <UserProfile /> },
  { path: "/idl/licenses", component: <LicenseRequestList /> },
  { path: "/idl/licenses/:id", component: <LicenseRequestDetail /> },
  { path: "/idl/voucherlimit", component: <VoucherLimitList /> },
  { path: "*", component: <Pages404 /> },
  { path: "/idl/voucherlimit", component: <VoucherLimitList /> },
  { path: "/idl/voucherlimit/notification", component: <NotificationList /> },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/login/:token", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "*", component: <Pages404 /> },
]

export { authProtectedRoutes, publicRoutes }
