//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"

//VOUCHER
export const GET_VOUCHER_LIMIT = "/VoucherLimit"

//LOGIN
export const GET_LOG_IN = "/Login"

export const GET_LICENSE_REQUEST_LIST = "/Licenserequest"
export const GET_LICENSE_REQUEST_DETAIL = "/Licenserequest"

//NOTIFICATIONS
export const GET_NOTIFICATION_LIST = "/Notification"
export const GET_NOTIFICATION = "/Notification"
export const SET_NOTIFICATION = "/Notification"
export const UPDATE_NOTIFICATION = "/Notification/update"
export const DELETE_NOTIFICATION = "/Notification"
