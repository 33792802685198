import React from 'react';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import dateFormat from "../../common/helpers/dateFormat"

const Grn = (cell) => {
    return <Link to="#" className="text-body fw-bold">{cell.value ? cell.value : ''}</Link>
};

const ProcessedDate = (cell) => {
    return cell.value ? <span>{dateFormat(cell.value)}</span> : "";
};

const TransactionDate = (cell) => {
    return cell.value ? <span>{dateFormat(cell.value)}</span> : "";
};

const TravelAgencyName = (cell) => {
    return cell.value ? cell.value : "";
};

const OfficeId = (cell) => {
    return cell.value ? cell.value : "";
};


const TotalAmount = (cell) => {
    return cell.value ? cell.value : "";
};

const Currency = (cell) => {
    return cell.value ? cell.value : "";
};

const CustomerFirstName = (cell) => {
    return cell.value ? cell.value : "";
};
const CustomerLastName = (cell) => {
    return cell.value ? cell.value : "";
};

const StaffName = (cell) => {
    return cell.value ? cell.value : "";
};

const Nationality = (cell) => {
    return cell.value ? cell.value : "";
};

const Commission = (cell) => {
    if(cell){
    const totalCommission = cell.value?.reduce((acc, obj) => acc + obj.commission, 0).toFixed(2);

    return totalCommission ? totalCommission : 0;
    }
    return "err"
};



const Status = (cell) => {
    switch(cell.value) {
        case "PAID":
            return <Badge className="bg-success">Paid</Badge>
        case "UNPAID":
            return <Badge className="bg-info">Unpaid</Badge>
        case "Close":
            return <Badge className="bg-danger">Pending</Badge>
    }
};


export { Grn, ProcessedDate, TransactionDate, TravelAgencyName,CustomerFirstName,CustomerLastName,Currency, OfficeId,StaffName,  TotalAmount, Commission ,Status, Nationality };