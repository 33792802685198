import {
  GET_NOTIFICATION_LIST,
  GET_NOTIFICATION_LIST_SUCCESS,
  GET_NOTIFICATION_LIST_FAIL,
  GET_NOTIFICATION_DETAIL,
  GET_NOTIFICATION_DETAIL_SUCCESS,
  GET_NOTIFICATION_DETAIL_FAIL,
  SET_NOTIFICATION,
  SET_NOTIFICATION_SUCCESS,
  SET_NOTIFICATION_FAIL,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAIL,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAIL,
  RESTART_STATE_NOTIFICATIONS,
} from "./actionTypes"

export const getNotificationList = () => ({
  type: GET_NOTIFICATION_LIST,
})
export const getNotificationListSuccess = data => ({
  type: GET_NOTIFICATION_LIST_SUCCESS,
  payload: data,
})
export const getNotificationListFail = error => ({
  type: GET_NOTIFICATION_LIST_FAIL,
  payload: error,
})

export const getNotificationDetail = data => ({
  type: GET_NOTIFICATION_DETAIL,
  payload: data,
})
export const getNotificationDetailSuccess = data => ({
  type: GET_NOTIFICATION_DETAIL_SUCCESS,
  payload: data,
})
export const getNotificationDetailFail = error => ({
  type: GET_NOTIFICATION_DETAIL_FAIL,
  payload: error,
})

export const setNotification = data => ({
  type: SET_NOTIFICATION,
  payload: data,
})
export const setNotificationSuccess = data => ({
  type: SET_NOTIFICATION_SUCCESS,
  payload: data,
})
export const setNotificationFail = error => ({
  type: SET_NOTIFICATION_FAIL,
  payload: error,
})

export const updateNotification = data => ({
  type: UPDATE_NOTIFICATION,
  payload: data,
})
export const updateNotificationSuccess = data => ({
  type: UPDATE_NOTIFICATION_SUCCESS,
  payload: data,
})
export const updateNotificationFail = error => ({
  type: UPDATE_NOTIFICATION_FAIL,
  payload: error,
})

export const deleteNotification = data => ({
  type: DELETE_NOTIFICATION,
  payload: data,
})
export const deleteNotificationSuccess = data => ({
  type: DELETE_NOTIFICATION_SUCCESS,
  payload: data,
})
export const deleteNotificationFail = error => ({
  type: DELETE_NOTIFICATION_FAIL,
  payload: error,
})

export const restartStateNotifications = () => ({
  type: RESTART_STATE_NOTIFICATIONS,
})
