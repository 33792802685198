import React from "react"
import PropTypes from "prop-types"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../Common/ChartsDynamicColor"
import LoadingSpinnerThreeDots from "components/Common/loadingSpinner"
import { Row } from "reactstrap"

const StackedColumnChart = ({
  dataColors = '["--bs-primary","--bs-secondary","--bs-success","--bs-blue", "--bs-indigo", "--bs-purple","--bs-pink","--bs-red","--bs-orange","--bs-yellow","--bs-green","--bs-teal","--bs-cyan","--bs-gray","--bs-gray-dark","--bs-info","--bs-warning","--bs-danger"]',
  periodData = [],
  categories = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  isLoading = true,
}) => {
  const stackedColumnChartColors = getChartColorsArray(dataColors)
  const options = {
    chart: {
      stacked: !0,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val
      },
      style: {
        fontSize: "12px",
        colors: ["#ffffff"],
      },
    },
    xaxis: {
      show: true,
      categories: categories,
      labels: {
        show: true,
      },
    },
    colors: stackedColumnChartColors,
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
  }
  return (
    <React.Fragment>
      {isLoading ? (
        <Row className="spinner-custom mx-auto my-auto pb-3">
          <LoadingSpinnerThreeDots />
        </Row>
      ) : null}
      <ReactApexChart
        options={options}
        series={[...periodData]}
        type="bar"
        height="359"
        className="apex-charts"
      />
    </React.Fragment>
  )
}

StackedColumnChart.propTypes = {
  periodData: PropTypes.any,
}
export default StackedColumnChart
