import React from 'react';

import { Spinner } from 'reactstrap';

const LoadingSpinner = () => {
    return (
        <div className="d-flex justify-content-center align-items-center vh-50">
               <Spinner type="grow" className="ms-2" color="primary" />
               <Spinner type="grow" className="ms-2" color="primary" />
               <Spinner type="grow" className="ms-2" color="primary" /> 
        </div>
    );
};



export default LoadingSpinner;