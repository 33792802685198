import React from "react"

export default function numberFormat(number) {

  //let num = (Math.round(number * 100) / 100).toFixed(2)
  return number?.toLocaleString('en-US', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2})
  // num.toLocaleString('en-US', {maximumFractionDigits:2})

  return number
}


