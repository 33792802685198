import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
  GET_LICENSE_REQUEST_DETAIL,
  GET_LICENSE_REQUEST_SUMMARY,
  GET_LICENSE_REQUEST_LIST,
  UPDATE_LICENSE_REQUEST_DETAIL,
} from "./actionTypes"

import {
  getLicenseRequestDetailSuccess,
  getLicenseRequestDetailFail,
  getLicenseRequestListSuccess,
  getLicenseRequestListFail,
  getLicenseRequestSummarySuccess,
  getLicenseRequestSummaryFail,
  updateLicenseRequestDetailSuccess,
  updateLicenseRequestDetailFail,
  returnStateTransaction,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getLicenseRequestDetail,
  getLicenseRequestList,
  getLicenseRequestSummary,
  updateLicenseRequestDetail
} from "helpers/fakebackend_helper"

function* _getLicenseRequestDetail({ id }) {
  try {
    const response = yield call(getLicenseRequestDetail, id)
    yield put(getLicenseRequestDetailSuccess(response.data))
  } catch (error) {
    yield put(getLicenseRequestDetailFail(error))
  }
}

function* _getLicenseRequestList() {
  try {
    const response = yield call(getLicenseRequestList)
 
    yield put(getLicenseRequestListSuccess(response.data))
  } catch (error) {
   
    yield put(getLicenseRequestListFail(error))
  }
}

function* _getLicenseRequestSummary({ customerId }) {
  try {
    const response = yield call(getLicenseRequestSummary, customerId)

    yield put(getLicenseRequestSummarySuccess(response.data))
  } catch (error) {
    yield put(getLicenseRequestSummaryFail(error))
  }
}

function* _updateLicenseRequestDetail({data}) {

  try {
    const response = yield call(updateLicenseRequestDetail,data)

    yield put(updateLicenseRequestDetailSuccess(response.data))
  } catch (error) {
    yield put(updateLicenseRequestDetailFail(error))
  }
}

function* LicenseRequestSaga() {
  yield takeEvery(GET_LICENSE_REQUEST_DETAIL, _getLicenseRequestDetail)
  yield takeEvery(GET_LICENSE_REQUEST_LIST, _getLicenseRequestList)
  yield takeEvery(GET_LICENSE_REQUEST_SUMMARY, _getLicenseRequestSummary)
  yield takeEvery(UPDATE_LICENSE_REQUEST_DETAIL, _updateLicenseRequestDetail)
}

export default LicenseRequestSaga
