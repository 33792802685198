

export const GET_CUSTOMER_LIST = "GET_CUSTOMER_LIST"
export const GET_CUSTOMER_LIST_SUCCESS = "GET_CUSTOMER_LIST_SUCCESS"
export const GET_CUSTOMER_LIST_FAIL = "GET_CUSTOMER_LIST_FAIL"

export const GET_OFFICE_LIST_BY_ID = "GET_OFFICE_LIST_BY_ID"
export const GET_OFFICE_LIST_BY_ID_SUCCESS = "GET_OFFICE_LIST_BY_ID_SUCCESS"
export const GET_OFFICE_LIST_BY_ID_FAIL = "GET_OFFICE_LIST_BY_ID_FAIL"

export const RETURN_STATE = "RETURN_STATE"
