
import {
GET_CUSTOMER_LIST,
GET_CUSTOMER_LIST_SUCCESS,
GET_CUSTOMER_LIST_FAIL,
GET_OFFICE_LIST_BY_ID,
GET_OFFICE_LIST_BY_ID_SUCCESS,
GET_OFFICE_LIST_BY_ID_FAIL,
RETURN_STATE,
} from "./actionTypes"

const INIT_STATE = {
  customerList: [],
  officeList: [],
  error: {},
  loading: false,
}

const Customer = (state = INIT_STATE, action) => {
  switch (action.type) {
    

    case GET_CUSTOMER_LIST:
      return (state = {
        ...state,
        customerList: action.payload,
        loading: true,
      })

    case GET_CUSTOMER_LIST_SUCCESS:
      return {
        ...state,
        customerList: action.payload,
        loading: false,
      }

    case GET_CUSTOMER_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: true,
      }

      case GET_OFFICE_LIST_BY_ID:
        return (state = {
          ...state,
          officeList: action.payload,
          loading: true,
        })
  
      case GET_OFFICE_LIST_BY_ID_SUCCESS:
        return {
          ...state,
          officeList: action.payload,
          loading: false,
        }
  
      case GET_OFFICE_LIST_BY_ID_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: true,
        }


    case RETURN_STATE:
      return {
        ...state,
        customerList: [],
        officeList: {},
        error: {},
        loading: undefined,
      }

    default:
      return state
  }
}

export default Customer
