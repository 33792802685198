import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import {
  GET_NOTIFICATION_LIST,
  SET_NOTIFICATION,
  GET_NOTIFICATION_DETAIL,
  UPDATE_NOTIFICATION,
  DELETE_NOTIFICATION,
  // RESTART_STATE_NOTIFICATIONS,
} from "./actionTypes"

import {
  getNotificationListSuccess,
  getNotificationListFail,
  setNotificationSuccess,
  setNotificationFail,
  getNotificationDetailSuccess,
  getNotificationDetailFail,
  restartStateNotifications,
  updateNotificationSuccess,
  updateNotificationFail,
  deleteNotificationSuccess,
  deleteNotificationFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getNotificationList,
  getNotificationDetail,
  setNotification,
  updateNotification,
  deleteNotification,
} from "helpers/fakebackend_helper"

function* _getLicenseRequestDetail({ id }) {
  try {
    const response = yield call(getLicenseRequestDetail, id)
    yield put(getLicenseRequestDetailSuccess(response.data))
  } catch (error) {
    yield put(getLicenseRequestDetailFail(error))
  }
}

function* _getNotificationList() {
  try {
    const response = yield call(getNotificationList)

    yield put(getNotificationListSuccess(response.data))
  } catch (error) {
    yield put(getNotificationListFail(error))
  }
  //yield put(returnState())
}
function* _getNotificationDetail({ payload: data }) {
  try {
    const response = yield call(getNotificationDetail, data)

    yield put(getNotificationDetailSuccess(response.data))
  } catch (error) {
    yield put(getNotificationDetailFail(error))
  }
  //yield put(returnState())
}

function* _setNotification({ payload: data }) {
  try {
    const response = yield call(setNotification, data)

    if (response.isSuccess) {
      yield put(setNotificationSuccess(response.data))
    }
  } catch (error) {
    yield put(setNotificationFail(error))
  }
}

function* _updateNotification({ payload: data }) {
  try {
    const response = yield call(updateNotification, data)

    if (response.isSuccess) {
      yield put(updateNotificationSuccess(response.data))
    }
  } catch (error) {
    yield put(updateNotificationFail(error))
  }
}

function* _deleteNotification({ payload: data }) {
  try {
    const response = yield call(deleteNotification, data)

    if (response.isSuccess) {
      yield put(deleteNotificationSuccess(response.data))
    }
  } catch (error) {
    yield put(deleteNotificationFail(error))
  }
}
function* NotificationSaga() {
  yield takeEvery(GET_NOTIFICATION_LIST, _getNotificationList)
  yield takeEvery(GET_NOTIFICATION_DETAIL, _getNotificationDetail)
  yield takeEvery(SET_NOTIFICATION, _setNotification)
  yield takeEvery(UPDATE_NOTIFICATION, _updateNotification)
  yield takeEvery(DELETE_NOTIFICATION, _deleteNotification)
  // yield takeEvery(RESTART_STATE_NOTIFICATIONS, _restartStateNotification)
}

export default NotificationSaga
