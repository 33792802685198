import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import TableContainer from "./TableContainer"
import {
  AddedBy,
  Amount,
  BankReference,
  Currency,
  Date,
  Limit,
  LastUpdate,
  LastUpdateBy,
  IsActive,
  CustomerId,
  CustomerName,
} from "./VoucherLimitColumn"
import { useFormik } from "formik"
import * as Yup from "yup"

import {
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import { use } from "i18next"

const VoucherLimit = props => {
  const {
    user,
    voucherLimits,
    liceseRequestSummary,
    onSetVoucherLimit,
    onGetVoucherLimitListById,
    onUpdateVoucherLimit,
    onGetLicenseRequestSummary,
    onDeleteVoucherLimit,
    onResetState
  } = props

  
  const columnsAdmin = useMemo(
    () => [
     
      {
        Header: "Added Date",
        accessor: "addedDate",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Date {...cellProps} />
        },
      },
      {
        Header: "CustomerId",
        accessor: "customerId",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <CustomerId {...cellProps} />
        },
      },
      {
        Header: "CustomerName",
        accessor: "customerName",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <CustomerName {...cellProps} />
        },
      },
      {
        Header: "Bank Reference #",
        accessor: "bankReferenceNumber",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <BankReference {...cellProps} />
        },
      },

      {
        Header: "Amount",
        accessor: "amount",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Amount {...cellProps} />
        },
      },

      {
        Header: "Currency",
        accessor: "currency",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Currency {...cellProps} />
        },
      },
      {
        Header: "Voucher Limit",
        accessor: "limit",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Limit {...cellProps} />
        },
      },

      {
        Header: "Active",
        accessor: "isActive",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <IsActive {...cellProps} />
        },
      },

      {
        Header: "Added By",
        accessor: "addedBy",
        disableFilters: true,
        Cell: cellProps => {
          return <AddedBy {...cellProps} />
        },
      },
      {
        Header: "Last Update",
        accessor: "lastUpdate",
        disableFilters: true,
        Cell: cellProps => {
          return <LastUpdate {...cellProps} />
        },
      },
      {
        Header: "Last Update By",
        accessor: "lastUpdateBy",
        disableFilters: true,
        Cell: cellProps => {
          return <LastUpdateBy {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "_id",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-info"
                  onClick={() =>
                    handleVoucherLimitEdit(cellProps.row.original)
                  }
                >
                  <i className="mdi mdi-pencil-outline" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </li>

              <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => 
                                        handleDelete(cellProps.row.original)
                                    }
                                >
                                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
            </ul>
          )
        },
      },
    ],
    []
  )

  const columnsCustomer = useMemo(
    () => [
      {
        Header: "Added Date",
        accessor: "addedDate",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Date {...cellProps} />
        },
      },
      {
        Header: "Bank Reference #",
        accessor: "bankReferenceNumber",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <BankReference {...cellProps} />
        },
      },

      {
        Header: "Amount",
        accessor: "amount",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Amount {...cellProps} />
        },
      },

      {
        Header: "Currency",
        accessor: "currency",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Currency {...cellProps} />
        },
      },
      {
        Header: "Voucher Limit",
        accessor: "limit",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Limit {...cellProps} />
        },
      },

      {
        Header: "Added By",
        accessor: "addedBy",
        disableFilters: true,
        Cell: cellProps => {
          return <AddedBy {...cellProps} />
        },
      },
      {
        Header: "Last Update",
        accessor: "lastUpdate",
        disableFilters: true,
        Cell: cellProps => {
          return <LastUpdate {...cellProps} />
        },
      },
      {
        Header: "Last Update By",
        accessor: "lastUpdateBy",
        disableFilters: true,
        Cell: cellProps => {
          return <LastUpdateBy {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "_id",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-info"
                  onClick={() =>
                    handleVoucherLimitEdit(cellProps.row.original)
                  }
                >
                  <i className="mdi mdi-pencil-outline" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </li>

              <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => 
                                        handleDelete(cellProps.row.original)
                                    }
                                >
                                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
            </ul>
          )
        },
      },
    ],
    []
  )
  const [columns, setColumns] = useState(columnsCustomer);
  const [modal, setModal] = useState(false)
  const [voucher, setVoucher] = useState(null)
  const [isEdit, setIsEdit] = useState(false)

  useEffect(() => {
    if (user.customerID === null && user.email.includes("@amadeus.qa")) 
    setColumns(columnsAdmin)
    else
    setColumns(columnsCustomer)
  }, [user]);

  useEffect(() => {
    if (voucher) {
      validation.setValues({
        bankReferenceNumber: voucher.bankReferenceNumber || "",
        amount: voucher.amount || "",
        limit: voucher.limit || "",
        currency: voucher.currency || "",
        addedBy: voucher.addedBy || "",
      })
    }
  }, [voucher])

  const toggle = () => {
    if (modal) {
      setModal(false)
      setVoucher(null)
    } else {
      setModal(true)
    }
  }

  const handleVoucherLimitEdit = arg => {
   

    const limit = arg

    setVoucher({
      _id: limit._id,
      bankReferenceNumber: limit.bankReferenceNumber,
      amount: limit.amount.toString(),
      limit: limit.limit.toString(),
      currency: limit.currency,
      addedBy: limit.addedBy,
      LastUpdate: limit.lastUpdateBy,
    })

    setIsEdit(true)

    toggle()
  }

  const handleDelete =  async arg => {
   

    const limit = arg

    const selected = {
      _id: limit._id,
      BankReferenceNumber: limit.bankReferenceNumber,
      Amount: limit.amount.toString(),
      Limit: limit.limit.toString(),
      Currency: limit.currency,
      AddedBy: limit.addedBy,
      AddedDate: limit.addedDate,
      LastUpdate: limit.lastUpdate,
      LastUpdateBy: limit.lastUpdateBy,
      Note:"Soft Deleted",
      isActive:false,
      CustomerId: user.customerID,
      CustomerName: user.agencyName,
    }


     await onDeleteVoucherLimit(selected)
     
      handleReloadTable()
  }


  const handleReloadTable = async () => {
    //this is to reload the table
    const filter = {
      Field: "customer",
      Id: user.customerID,
    }

     onGetVoucherLimitListById(filter)
     onGetLicenseRequestSummary(filter.Id)
 
  }

 

  const initialValues = {
    bankReferenceNumber: (voucher && voucher.bankReferenceNumber) || "",
    amount: (voucher && voucher.amount) || "",
    limit: (voucher && voucher.limit) || "",
    currency: (voucher && voucher.currency) || "",
    addedBy: (voucher && voucher.addedBy) || "",
  }

  const validationSchema = Yup.object({
    amount: Yup.string()
      .matches("[0-9]+", "Please Enter Valid Amount Deposited")
      .required("Please Enter Amount Deposited"),
    limit: Yup.string()
      .matches("[0-9]+", "Please Enter Valid Voucher Limit")
      .required("Please Enter Voucher Limit"),
    bankReferenceNumber: Yup.string().required("Please Enter Bank Reference #"),
    currency: Yup.string().required("Please Select Currency")
  })

  const onSubmit = async values => {
    if (isEdit) {
      const updateVoucherLimit = {
        _id: voucher ? voucher._id : 0,
        bankReferenceNumber: values.bankReferenceNumber,
        amount: parseFloat(values.amount),
        limit: values.limit,
        currency: values.currency,
        addedBy: user.email,
        lastUpdateBy: user.email,
        customerId: user.customerID,
        customerName: user.agencyName,
        note:"",
        isActive:true
      }

      // update Voucher Limit
      await onUpdateVoucherLimit(updateVoucherLimit)
      validation.resetForm()
    } else {
      const newVoucherLimit = {
        _id: "",
        lastUpdateBy: "",
        customerId: user.customerID,
        customerName: user.agencyName,
        bankReferenceNumber: values["bankReferenceNumber"],
        amount: parseFloat(values["amount"]),
        limit: values["limit"],
        currency: values["currency"],
        addedBy: user.email,
        note:"",
        isActive:true
      }

      // save new Voucher Limit
      await onSetVoucherLimit(newVoucherLimit)
      validation.resetForm()

    }
    toggle()

    handleReloadTable()
  }

  const validation = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">
          {!!isEdit ? "Edit Voucher" : "Add Voucher Limit"}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={validation.handleSubmit}>
            <Row>
              <Col className="col-12">
                <div className="mb-3">
                  <Label className="form-label">Bank Reference #</Label>
                  <Input
                    name="bankReferenceNumber"
                    type="text"
                    placeholder="Insert Bank Reference #"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.bankReferenceNumber || ""}
                    invalid={
                      validation.touched.bankReferenceNumber &&
                      validation.errors.bankReferenceNumber
                        ? true
                        : false
                    }
                  />
                  {validation.touched.bankReferenceNumber &&
                  validation.errors.bankReferenceNumber ? (
                    <FormFeedback type="invalid">
                      {validation.errors.bankReferenceNumber}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">Amount Deposited in EUR</Label>
                  <Input
                    name="amount"
                    type="text"
                    placeholder="Insert Amount Deposited"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.amount || ""}
                    invalid={
                      validation.touched.amount && validation.errors.amount
                        ? true
                        : false
                    }
                  />
                  {validation.touched.amount && validation.errors.amount ? (
                    <FormFeedback type="invalid">
                      {validation.errors.amount}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">
                    Equivalent Voucher Limit Balance
                  </Label>
                  <Input
                    name="limit"
                    placeholder="Voucher Limit Balance"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.limit || ""}
                    invalid={
                      validation.touched.limit && validation.errors.limit
                        ? true
                        : false
                    }
                  />
                  {validation.touched.limit && validation.errors.limit ? (
                    <FormFeedback type="invalid">
                      {validation.errors.limit}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">Currency</Label>
                  <Input
                    name="currency"
                    type="select"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.currency || ""}
                    invalid={
                      validation.touched.currency && validation.errors.currency
                        ? true
                        : false
                    }
                  >
                    <option value="" defaultValue hidden>
                      Select
                    </option>
                    <option value="EUR">EUR</option>
                  </Input>
                  {validation.touched.currency && validation.errors.currency ? (
                    <FormFeedback status="invalid">
                      {validation.errors.currency}
                    </FormFeedback>
                  ) : null}
                </div>
                
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user">
                    Submit
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>

      <Row>
        <Col lg="12">
          <TableContainer
            columns={columns}
            data={voucherLimits}
            isGlobalFilter={true}
            isAddOptions={true}
            isAddTopUp={setModal}
            liceseRequestSummary={liceseRequestSummary}
            isSearchFilter={false}
            customPageSize={50}
          />
        </Col>
      </Row>
    </>
  )
}

VoucherLimit.propTypes = {}

export default VoucherLimit
