import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import { Table, Row, Col, Button, Input, CardBody, Card } from "reactstrap"
import { Filter, DefaultColumnFilter } from "../../components/Common/filters"
import SearchFilter from "./SearchFilter"
import numberFormat from "common/helpers/numberFormat"
import { isEmpty } from "lodash"
import authDetails from "../../helpers/auth-details/auth-details"

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isSearchFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <React.Fragment>
      <Col md={4}>
        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value)
                  onChange(e.target.value)
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`${count} records...`}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      {isSearchFilter && <SearchFilter />}
    </React.Fragment>
  )
}

const TableContainer = ({
  columns,
  data,
  isGlobalFilter,
  isSearchFilter,
  isAddOptions,
  isAddUserList,
  isAddTopUp,
  handleOrderClicks,
  liceseRequestSummary,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  className,
  customPageSizeOptions,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )
  const [user, setUser] = useState(authDetails)
  const [totalLimitBalance, setTotalLimitBalance] = useState()
  const [totalAmountDeposited, setTotalAmountDeposited] = useState()
  const [totalEquivalentLimit, setTotalEquivalentLimit] = useState()
  const [currency, setCurrency] = useState()

  useEffect(() => {
    if (!isEmpty(rows)) {
      let sum = rows?.reduce(
        (accumulator, currentValue) => accumulator + currentValue.values.amount,
        0
      )

      let sumLimit = rows?.reduce(
        (accumulator, currentValue) => accumulator + currentValue.values.limit,
        0
      )

      setTotalEquivalentLimit(sumLimit)
      setTotalAmountDeposited(sum)
      setCurrency(rows[0].values?.currency)
    }
  }, [rows])

  useEffect(() => {
    if (liceseRequestSummary && totalEquivalentLimit) {
      let remainingBalance =
        parseFloat(totalEquivalentLimit) -
        parseFloat(liceseRequestSummary?.revenue)
      setTotalLimitBalance(numberFormat(remainingBalance))
    }
  }, [liceseRequestSummary, totalEquivalentLimit])

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }
  return (
    <Fragment>
      <Card>
        <CardBody>
          <Row>
            <Col lg="12">
              <span>
                <span style={{ color: "red" }}>Disclaimer :</span> This is a
                monitoring tool and not the official voucher limit balance
                provided by ITCA. For any information regarding your voucher
                limit balance, kindly get in touch with the ITCA team. This
                feature is inteded for those with vouchers.
              </span>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Row>
        <Col>
          <Card className="mini-stats-wid">
            <CardBody>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <p className="text-muted fw-medium">
                    Total Amount Deposited in EUR{" "}
                  </p>
                  <h4 className="mb-0">{numberFormat(totalAmountDeposited)}</h4>
                </div>
                <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                  <span className="avatar-title rounded-circle bg-primary">
                    <i className={"bx bx-id-card font-size-24"}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col>
          <Card className="mini-stats-wid">
            <CardBody>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <p className="text-muted fw-medium">
                    Equivalent Voucher Limit{" "}
                  </p>
                  <h4 className="mb-0">{numberFormat(totalEquivalentLimit)}</h4>
                </div>
                <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                  <span className="avatar-title rounded-circle bg-primary">
                    <i className={"bx bx-id-card font-size-24"}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col>
          <Card className="mini-stats-wid">
            <CardBody>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <p className="text-muted fw-medium">Remaining Balance </p>
                  <h4 className="mb-0">{numberFormat(totalLimitBalance)} </h4>
                </div>
                <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                  <span className="avatar-title rounded-circle bg-primary">
                    <i className={"bx bx-money font-size-24"}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Card>
        <CardBody>
          <Row className="mb-2">
            <Col md={customPageSizeOptions ? 2 : 1}>
              <select
                className="form-select"
                value={pageSize}
                onChange={onChangeInSelect}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </Col>
            {isGlobalFilter && (
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                isSearchFilter={isSearchFilter}
              />
            )}
            {isAddOptions && (
              <>
                {user.customerID === null &&
                user.email.includes("@amadeus.qa") ? (
                  <>
                    <Col>
                      <div className="text-sm-end">
                        <Button
                          disabled
                          type="button"
                          color="success"
                          className="btn-rounded  mb-2 me-2"

                        >
                          <i className="mdi mdi-plus me-1" />
                          Top Up Balance
                        </Button>
                      </div>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col>
                      <div className="text-sm-end">
                        <Button
                          type="button"
                          color="success"
                          className="btn-rounded  mb-2 me-2"
                          onClick={() => isAddTopUp(true)}
                        >
                          <i className="mdi mdi-plus me-1" />
                          Top Up Balance
                        </Button>
                      </div>
                    </Col>
                  </>
                )}
              </>
            )}
            {isAddUserList && (
              <Col>
                <div className="text-sm-end">
                  <Button
                    type="button"
                    color="primary"
                    className="btn mb-2 me-2"
                    onClick={handleUserClick}
                  >
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    Create New User
                  </Button>
                </div>
              </Col>
            )}
            {isAddCustList && (
              <Col>
                <div className="text-sm-end">
                  <Button
                    type="button"
                    color="success"
                    className="btn-rounded mb-2 me-2"
                    onClick={handleCustomerClick}
                  >
                    <i className="mdi mdi-plus me-1" />
                    New Customers
                  </Button>
                </div>
              </Col>
            )}
          </Row>

          <div className="table-responsive react-table">
            <Table bordered hover {...getTableProps()} className={className}>
              <thead className="table-light table-nowrap">
                {headerGroups.map(headerGroup => (
                  <tr
                    key={headerGroup.id}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map(column => (
                      <th key={column.id}>
                        <div
                          className="mb-2"
                          {...column.getSortByToggleProps()}
                        >
                          {column.render("Header")}
                          {generateSortingIndicator(column)}
                        </div>
                        <Filter column={column} />
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {page.map(row => {
                  prepareRow(row)
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <tr>
                        {row.cells.map(cell => {
                          return (
                            <td key={cell.id} {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          )
                        })}
                      </tr>
                    </Fragment>
                  )
                })}
              </tbody>
            </Table>
          </div>

          <Row className="justify-content-md-end justify-content-center align-items-center">
            <Col className="col-md-auto">
              <div className="d-flex gap-1">
                <Button
                  color="primary"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  {"<<"}
                </Button>
                <Button
                  color="primary"
                  onClick={previousPage}
                  disabled={!canPreviousPage}
                >
                  {"<"}
                </Button>
              </div>
            </Col>
            <Col className="col-md-auto d-none d-md-block">
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </Col>
            <Col className="col-md-auto">
              <Input
                type="number"
                min={1}
                style={{ width: 70 }}
                max={pageOptions.length}
                defaultValue={pageIndex + 1}
                onChange={onChangeInInput}
              />
            </Col>

            <Col className="col-md-auto">
              <div className="d-flex gap-1">
                <Button
                  color="primary"
                  onClick={nextPage}
                  disabled={!canNextPage}
                >
                  {">"}
                </Button>
                <Button
                  color="primary"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">>"}
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TableContainer
