export const GET_NOTIFICATION_LIST = "GET_NOTIFICATION_LIST"
export const GET_NOTIFICATION_LIST_SUCCESS = "GET_NOTIFICATION_LIST_SUCCESS"
export const GET_NOTIFICATION_LIST_FAIL = "GET_NOTIFICATION_LIST_FAIL"

export const GET_NOTIFICATION_DETAIL = "GET_NOTIFICATION_DETAIL"
export const GET_NOTIFICATION_DETAIL_SUCCESS = "GET_NOTIFICATION_DETAIL_SUCCESS"
export const GET_NOTIFICATION_DETAIL_FAIL = "GET_NOTIFICATION_DETAIL_FAIL"

export const SET_NOTIFICATION = "SET_NOTIFICATION"
export const SET_NOTIFICATION_SUCCESS = "SET_NOTIFICATION_SUCCESS"
export const SET_NOTIFICATION_FAIL = "SET_NOTIFICATION_FAIL"

export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION"
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS"
export const UPDATE_NOTIFICATION_FAIL = "UPDATE_NOTIFICATION_FAIL"

export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION"
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS"
export const DELETE_NOTIFICATION_FAIL = "DELETE_NOTIFICATION_FAIL"

export const RESTART_STATE_NOTIFICATIONS = "RESTART_STATE_NOTIFICATIONS"
