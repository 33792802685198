import error_handler_helper from "helpers/error_handler_helper"
import {
  GET_LICENSE_REQUEST_DETAIL,
  GET_LICENSE_REQUEST_DETAIL_FAIL,
  GET_LICENSE_REQUEST_DETAIL_SUCCESS,
  GET_LICENSE_REQUEST_LIST,
  GET_LICENSE_REQUEST_LIST_SUCCESS,
  GET_LICENSE_REQUEST_LIST_FAIL,
  GET_LICENSE_REQUEST_SUMMARY,
  GET_LICENSE_REQUEST_SUMMARY_FAIL,
  GET_LICENSE_REQUEST_SUMMARY_SUCCESS,
  UPDATE_LICENSE_REQUEST_DETAIL,
  UPDATE_LICENSE_REQUEST_DETAIL_SUCCESS ,
  UPDATE_LICENSE_REQUEST_DETAIL_FAIL,
  RETURN_STATE,
} from "./actionTypes"

const INIT_STATE = {
  licenseRequestList: [],
  licenseRequest: {},
  summary:{},
  error: {},
  loading: false,
  updateSuccess: undefined,
}

const LicenseRequest = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LICENSE_REQUEST_DETAIL:
      return {
        ...state,
        licenseRequest: action.payload,
        loading: true,
      }

    case GET_LICENSE_REQUEST_DETAIL_SUCCESS:
      return {
        ...state,
        licenseRequest: action.payload,
        loading: false,
      }

    case GET_LICENSE_REQUEST_DETAIL_FAIL:
      return {
        ...state,
        error: error_handler_helper(action.payload),
        loading: true,
      }

    case GET_LICENSE_REQUEST_LIST:
      return (state = {
        ...state,
        licenseRequestList: action.payload,
        loading: true,
      })

    case GET_LICENSE_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        licenseRequestList: action.payload,
        loading: false,
      }

    case GET_LICENSE_REQUEST_LIST_FAIL:
      return {
        ...state,
        error: error_handler_helper(action.payload),
        loading: true,
      }

      case GET_LICENSE_REQUEST_SUMMARY:
        return {
          ...state,
          summary: action.payload,
          loading: true,
        }
  
      case GET_LICENSE_REQUEST_SUMMARY_SUCCESS:
        return {
          ...state,
          summary: action.payload,
          loading: false,
        }
  
      case GET_LICENSE_REQUEST_SUMMARY_FAIL:
        return {
          ...state,
          error: error_handler_helper(action.payload),
          loading: true,
        }
        
        case UPDATE_LICENSE_REQUEST_DETAIL:
          return (state = {
            ...state,
            licenseRequest: action.payload,
            updateSuccess: undefined,
          })
    
        case UPDATE_LICENSE_REQUEST_DETAIL_SUCCESS:
          return {
            ...state,
            licenseRequest: action.payload,
            updateSuccess: true,
          }
    
        case UPDATE_LICENSE_REQUEST_DETAIL_FAIL:
          return {
            ...state,
            error: error_handler_helper(action.payload),
            updateSuccess: false,
          }

    case RETURN_STATE:
      return {
        ...state,
        licenseRequestList: [],
        licenseRequest: {},
        summary:{},
        error: {},
        loading: undefined,
      }

    default:
      return state
  }
}

export default LicenseRequest
