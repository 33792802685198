export default function DateToday() {
  var dateToday = new Date()

  switch (dateToday.getMonth() + 1) {
    case 1:
      return { label: "Jan", value: 1 }
      break
    case 2:
      return { label: "Feb", value: 2 }
      break
    case 3:
      return { label: "Mar", value: 3 }
      break
    case 4:
      return { label: "Apr", value: 4 }
      break
    case 5:
      return { label: "May", value: 5 }
      break
    case 6:
      return { label: "Jun", value: 6 }
      break
    case 7:
      return { label: "Jul", value: 7 }
      break
    case 8:
      return { label: "Aug", value: 8 }
      break
    case 9:
      return { label: "Sep", value: 9 }
      break
    case 10:
      return { label: "Oct", value: 10 }
      break
    case 11:
      return { label: "Nov", value: 11 }
      break
    case 12:
      return { label: "Dec", value: 12 }
      break
  }
}
