import {
 GET_CUSTOMER_LIST,
 GET_CUSTOMER_LIST_SUCCESS,
GET_CUSTOMER_LIST_FAIL,
GET_OFFICE_LIST_BY_ID,
GET_OFFICE_LIST_BY_ID_SUCCESS,
GET_OFFICE_LIST_BY_ID_FAIL,
RETURN_STATE,
} from "./actionTypes"

export const getCustomerList = () => ({
  type: GET_CUSTOMER_LIST,
})

export const getCustomerListSuccess = data => ({
  type: GET_CUSTOMER_LIST_SUCCESS,
  payload: data,
})

export const getCustomerListFail = error => ({
  type: GET_CUSTOMER_LIST_FAIL,
  payload: error,
})


export const getOfficeListById = customerId => ({
  type: GET_OFFICE_LIST_BY_ID,
  customerId,
})

export const getOfficeListByIdSuccess = data => ({
  type: GET_OFFICE_LIST_BY_ID_SUCCESS,
  payload: data,
})

export const getOfficeListByIdFail = error => ({
  type: GET_OFFICE_LIST_BY_ID_FAIL,
  payload: error,
})



