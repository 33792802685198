import StackedColumnChart from "components/Charts/StackedColumnChart"
import React, { useEffect, useMemo, useState } from "react"
import { Card, Row, CardBody, CardTitle, Col, Label } from "reactstrap"
import Barchart from "components/Charts/barchart"
import { Link } from "react-router-dom"
import classNames from "classnames"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import {
  getTravelAgencyStaffData as onGetTravelAgencyStaffData,
  getTravelAgencyData as ongGetTravelAgencyData,
} from "store/actions"
import authDetails from "helpers/auth-details/auth-details"
import TableContainer from "../TableContainers/TableContainer"
import DateToday from "./DateToday"
import { isEmpty } from "lodash"

const TravelAgencyView = ({ selectedYear }) => {
  const dispatch = useDispatch()

  const { stateStaffData, stateData } = useSelector(state => ({
    stateStaffData: state.Dashboard.travelAgencyStaffData.data,
    stateData: state.Dashboard.travelAgencyData.data,
  }))

  const [user, setUser] = useState(authDetails)
  const [staffData, setStaffData] = useState({})
  const [data, setData] = useState([])
  const [displayedData, setDisplayedData] = useState([])
  const [selectedMonth, setSelectedMonth] = useState(DateToday())

  const [selectedValue, setSelectedValue] = useState({
    label: "Sales",
    value: 0,
  })

  const selectOptions = [
    {
      options: [
        { label: "Sales", value: 0 },
        { label: "Transactions", value: 1 },
      ],
    },
  ]

  const columnsMonthlySummary = useMemo(
    () => [
      {
        Header: "Office Id",
        accessor: "officeId",
        disableFilters: true,
      },
      {
        Header: "Transactions",
        accessor: "count",
        disableFilters: true,
      },
      {
        Header: "Sales",
        accessor: "revenue",
        disableFilters: true,
      },

      {
        Header: "Month",
        accessor: "month",
        disableFilters: true,
      },
    ],
    []
  )

  const columnsStaff = useMemo(
    () => [
      {
        Header: "Office Id",
        accessor: "officeId",
        disableFilters: true,
      },
      {
        Header: "Staff ",
        accessor: "staffName",
        disableFilters: true,
      },
      {
        Header: "Transactions",
        accessor: "count",
        disableFilters: true,
      },
      {
        Header: "Sales",
        accessor: "revenue",
        disableFilters: true,
      },
    ],
    []
  )

  useEffect(() => {
    dispatch(onGetTravelAgencyStaffData(selectedMonth.value, selectedYear))
    dispatch(ongGetTravelAgencyData("monthly", selectedYear))
  }, [selectedMonth, selectedYear])

  useEffect(() => {
    if (stateData !== undefined) {
      setData(stateData)
      setDisplayedData(stateData.revenues)
    }
  }, [stateData])

  useEffect(() => {
    if (stateStaffData !== undefined) {
      setStaffData(stateStaffData)
    }
  }, [stateStaffData])

  function onChangeData(item) {
    setSelectedValue(item)
    if (item.value === 0) {
      setDisplayedData(data.revenues)
    } else {
      setDisplayedData(data.productivities)
    }
  }

  // function handleSelectGroup(selectedGroup) {
  //   setselectedGroup(selectedGroup)
  //   dispatch(onGetTravelAgencyStaffData(selectedGroup.value, selectedYear))
  // }

  return (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <div className="d-flex">
              <CardTitle>{user.agencyName}</CardTitle>
              <div className="ms-auto">
                <Select
                  value={selectedValue}
                  onChange={onChangeData}
                  options={selectOptions}
                  className="select2-selection"
                />
              </div>
            </div>

            <StackedColumnChart
              isLoading={false}
              periodData={displayedData}
              dataColors='["--bs-primary", "--bs-warning", "--bs-success"]'
            />
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            {!isEmpty(data?.data) && (
              <TableContainer
                columns={columnsMonthlySummary}
                data={data?.data}
                customPageSize={10}
                isGlobalFilter={true}
              />
            )}
          </CardBody>
        </Card>
      </Col>

      <Col>
        <Card>
          <CardBody>
            <div className="d-flex">
              <CardTitle> Staff Productivity!</CardTitle>
              <div className="ms-auto">
                <Select
                  value={selectedMonth}
                  onChange={value => setSelectedMonth(() => value)}
                  options={staffData?.optionGroup?.sort(
                    (a, b) => b.value - a.value
                  )}
                  className="select2-selection"
                />
              </div>
            </div>
            <Barchart
              categories={staffData?.staffProductivityName}
              data={staffData?.staffProductivityData}
              isLoading={false}
              dataLabels={{
                enabled: true,
                formatter: function (val) {
                  return val
                },
                style: {
                  fontSize: "12px",
                },
              }}
              dataColors='["--bs-success"]'
            />
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            {!isEmpty(staffData?.data) && (
              <TableContainer
                columns={columnsStaff}
                data={staffData?.data}
                customPageSize={10}
                isGlobalFilter={true}
              />
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default TravelAgencyView
