import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link, useParams } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Breadcrumb, Card, CardBody, Col, Container, Row } from "reactstrap"
import Overview from "./Overview"
import Transaction from "./Transaction"
import { getLicenseRequestDetail } from "store/actions"
import { withTranslation } from "react-i18next";
import LoadingSpinner from "common/helpers/loadingSpinner"

const LicenseRequestDetail = props => {
  const { onGetLicenseRequestDetail, licenseRequestDetail, loading, error } = props

  let { id } = useParams()



  const [license, setLicense] = useState({})
  const [loader, setLoader] = useState()
  const [err, setErr] = useState()

  useEffect(() => {
    if (id) {
      onGetLicenseRequestDetail(id)
    }
  }, [id, onGetLicenseRequestDetail])

  useEffect(() => {
    if (licenseRequestDetail) {
      setLicense(licenseRequestDetail)
    }
  }, [licenseRequestDetail])

  useEffect(() => {}, [license])

  useEffect(() => {
    setLoader(loading)
  }, [loading])

  useEffect(() => {
   
    setErr(error)
  }, [error])

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
          {loader ? (
            <LoadingSpinner />
          ) : (
            <>
            <Link to="/idl/licenses">
            <Breadcrumbs
            title={license.grn}
            breadcrumbItem = {<span><i className="bx bx-left-arrow-alt"/> Licenses</span> }
          />
            </Link>
          
            <Row>
              {license && (
                <>
                  <Col xl={4}>
                  <Overview license={license} />
                  </Col>
                  <Col>
                    <Transaction license={license} />
                  </Col>
                </>
              )}
            </Row>
            </>
            )}
          </Container>
        </div>
      </React.Fragment>
    </>
  )
}

LicenseRequestDetail.propTypes = {
  match: PropTypes.any,
}

const mapStateToProps = state => (

  {
    licenseRequestDetail: state.LicenseRequest.licenseRequest,
    loading: state.LicenseRequest.loading,
    error: state.LicenseRequest.error,
  }
)

const mapDispatchToProps = dispatch => ({
  onGetLicenseRequestDetail: id => dispatch(getLicenseRequestDetail(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LicenseRequestDetail)
