import {
  GET_LICENSE_REQUEST_DETAIL,
  GET_LICENSE_REQUEST_DETAIL_FAIL,
  GET_LICENSE_REQUEST_DETAIL_SUCCESS,
  GET_LICENSE_REQUEST_LIST,
  GET_LICENSE_REQUEST_LIST_SUCCESS,
  GET_LICENSE_REQUEST_LIST_FAIL,
  GET_LICENSE_REQUEST_SUMMARY,
  GET_LICENSE_REQUEST_SUMMARY_FAIL,
  GET_LICENSE_REQUEST_SUMMARY_SUCCESS,
  UPDATE_LICENSE_REQUEST_DETAIL,
  UPDATE_LICENSE_REQUEST_DETAIL_SUCCESS ,
  UPDATE_LICENSE_REQUEST_DETAIL_FAIL,

  RETURN_STATE,
} from "./actionTypes"

export const getLicenseRequestList = () => ({
  type: GET_LICENSE_REQUEST_LIST
})

export const getLicenseRequestListSuccess = data => ({
  type: GET_LICENSE_REQUEST_LIST_SUCCESS,
  payload: data,
})

export const getLicenseRequestListFail = error => ({
  type: GET_LICENSE_REQUEST_LIST_FAIL,
  payload: error,
})



export const getLicenseRequestDetail = id => ({
  type: GET_LICENSE_REQUEST_DETAIL,
  id,
})

export const getLicenseRequestDetailSuccess = data => ({
  type: GET_LICENSE_REQUEST_DETAIL_SUCCESS,
  payload: data,
})

export const getLicenseRequestDetailFail = error => ({
  type: GET_LICENSE_REQUEST_DETAIL_FAIL,
  payload: error,
})

export const getLicenseRequestSummary = customerId => ({
  type: GET_LICENSE_REQUEST_SUMMARY,
  customerId,
})

export const getLicenseRequestSummarySuccess = data => ({
  type: GET_LICENSE_REQUEST_SUMMARY_SUCCESS,
  payload: data,
})

export const getLicenseRequestSummaryFail = error => ({
  type: GET_LICENSE_REQUEST_SUMMARY_FAIL,
  payload: error,
})


export const updateLicenseRequestDetail = data => ({
  type: UPDATE_LICENSE_REQUEST_DETAIL,
  data
})

export const updateLicenseRequestDetailSuccess = data => ({
  type: UPDATE_LICENSE_REQUEST_DETAIL_SUCCESS,
  payload: data,
})

export const updateLicenseRequestDetailFail = error => ({
  type: UPDATE_LICENSE_REQUEST_DETAIL_FAIL,
  payload: error,
})
