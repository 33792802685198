import React from "react"
import { Navigate } from "react-router-dom"
import * as jose from "jose"
import authHeader from "helpers/jwt-token-access/auth-token-header"

const Authmiddleware = props => {
  if (!localStorage.getItem("authUser")) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    )
  } else {
    const secret = new TextEncoder().encode(
      "f-QUrS2QRvNJgK7uVt@P6cADSv8jA@FvsAZZ7$3LAR2LU52G"
    )
    const jwt = authHeader().Authorization

    async function validateToken() {
      await jose.jwtVerify(jwt, secret, {}).catch(error => {
        console.error(error)
        localStorage.removeItem("authUser")
        return (
          <Navigate
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      })
    }
    validateToken()
  }
  return <React.Fragment>{props.children}</React.Fragment>
}

export default Authmiddleware
