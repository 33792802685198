import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { Container } from "reactstrap"
import { isEmpty } from "lodash"
import {
  getLicenseRequestList,
  getCustomerList,
  getOfficeListById,
  updateLicenseRequestDetail,
  getVoucherLimitListById,
} from "store/actions"
import LicenseRequest from "./LicenseRequest"
import LoadingSpinner from "common/helpers/loadingSpinner"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import authDetails from "../../helpers/auth-details/auth-details"

const LicenseRequestList = props => {
  const {
    onGetLicenseRequestList,
    licenseRequestList,
    onGetCustomersList,
    onGetOfficeListByCustomerId,
    onUpdateLicenseRequestDetail,
    onGetVoucherLimitListById,
    customerList,
    voucherLimitList,
    officeList,
    loading,
    isUpdateSuccess,
    error,
  } = props

  const [user, setUser] = useState(authDetails)
  const [licenses, setLicenses] = useState()
  const [customers, setCustomers] = useState()
  const [voucherLimits, setVoucherLimits] = useState()
  const [offices, setOffices] = useState()
  const [loader, setLoader] = useState()
  const [err, setErr] = useState()
 


  
  useEffect(() => {
    var filter = {
      Field: "customer",
      Id: user.customerID,
    }
    onGetVoucherLimitListById(filter)
    onGetLicenseRequestList()
  }, [onGetLicenseRequestList])

  useEffect(() => {
    if (licenseRequestList) {
      setLicenses(licenseRequestList)
    }
  }, [licenseRequestList])

  useEffect(() => {
    if (customerList) {
      setCustomers(customerList)
    }
  }, [customerList])

  useEffect(() => {
    if (officeList) {
      setOffices(officeList)
    }
  }, [officeList])

  useEffect(() => {
    setVoucherLimits(voucherLimitList)
  }, [voucherLimitList]);

  useEffect(() => {
    setLoader(loading)
  }, [loading])

  useEffect(() => {
  
    setErr(error)
  }, [error])

  useEffect(() => {}, [licenses, customers, offices])


  toastr.options = {
    positionClass: "toast-top-right",
    timeOut: 5000,
    extendedTimeOut: 1000,
    closeButton: true,
    debug: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 1000,
  }

  function showToastSuccess() {
    toastr.success("Update Success", "")
  }

  function showToastError() {
    toastr.error("Not updated. Something went wrong.", "")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

        {isUpdateSuccess && showToastSuccess() }
            {isUpdateSuccess === false && showToastError()}

          {loader ? (
            <LoadingSpinner />
          ) : (
            <>
              {licenses && (
                <LicenseRequest
                  user={user}
                  licenses={licenses}
                  customers={customers}
                  offices={offices}
                  voucherLimits={voucherLimits}
                  onGetCustomersList={onGetCustomersList}
                  onGetOfficeListByCustomerId={onGetOfficeListByCustomerId}
                  onUpdateLicenseRequestDetail={onUpdateLicenseRequestDetail}
                  onGetLicenseRequestList={onGetLicenseRequestList}
               
                />
              )}
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

LicenseRequestList.propTypes = {}

const mapStateToProps = state => (

  {
    licenseRequestList: state.LicenseRequest.licenseRequestList,
    voucherLimitList: state.VoucherLimit.voucherLimitList,
    isUpdateSuccess:state.LicenseRequest.updateSuccess,
    loading: state.LicenseRequest.loading,
    error: state.LicenseRequest.error,
    customerList: state.Customer.customerList,
    officeList: state.Customer.officeList,
  }
)

const mapDispatchToProps = dispatch => ({
  onGetLicenseRequestList: () => dispatch(getLicenseRequestList()),
  onGetCustomersList: () => dispatch(getCustomerList()),
  onGetOfficeListByCustomerId: customerId => dispatch(getOfficeListById(customerId)),
  onUpdateLicenseRequestDetail: data => dispatch(updateLicenseRequestDetail(data)),
  onGetVoucherLimitListById: filter => dispatch(getVoucherLimitListById(filter)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LicenseRequestList)
