import axios from "axios"
import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"

// licenserequest
export const getLicenseRequestList = () => get("/LicenseRequest")
export const getLicenseRequestDetail = id =>
  get(`${url.GET_LICENSE_REQUEST_DETAIL}/${id}`, { params: { id } })
export const getLicenseRequestSummary = customerId =>
  get("LicenseRequest/summary/" + customerId)
export const updateLicenseRequestDetail = data =>
  post("LicenseRequest/update", data)

export const postLogin = data => post("/user/credentials", data)

export const preLogin = token => get(`/user/credentials/token/` + token)

//voucherlimit
export const getVoucherLimitListById = filter =>
  post(url.GET_VOUCHER_LIMIT, filter)
export const setVoucherLimit = data => post("/VoucherLimit/insert", data)
export const updateVoucherLimit = data => post("/VoucherLimit/update", data)
export const deleteVoucherLimit = data => post("/VoucherLimit/delete", data)

//notifications
export const getNotificationList = () => get(url.GET_NOTIFICATION_LIST)
export const getNotificationDetail = id => get(url.GET_NOTIFICATION + "/" + id)
export const setNotification = data => post(url.SET_NOTIFICATION, data)
export const updateNotification = data => post(url.UPDATE_NOTIFICATION, data)
export const deleteNotification = id => del(url.DELETE_NOTIFICATION + "/" + id)

//customer
export const getCustomerList = () => get("Morningstars/customers")
export const getOfficeListById = customerId =>
  get("Morningstars/offices/" + customerId)

export const getVoucherLimitDetail = id =>
  get(`${url.GET_VOUCHER_LIMIT}/${id}`, { params: { id } })

export const getData = data => get("/dashboard/admin/" + data)
export const getStaffData = () => get("dashboard/staff")
export const getVoucherLimitList = () => get(url.GET_VOUCHER_LIMIT)

export const getDashboardData = data => post("/dashboard/admin", data)

export const getTravelAgencyData = data =>
  get("dashboard/travel-agency/" + data.periodType + "/" + data.year)

export const getTravelAgencyStaffData = data =>
  get("/Dashboard/travel-agency/staff/" + data.periodType + "/" + data.year)

export const getTopTravelAgencyData = data =>
  get("Dashboard/admin/top/" + data.periodType + "/" + data.year)

//TEMP REMOVE ON OFFICIAL DEPLOYMENT FOR PRODUCTION
export const postJwtForgetPwd = data => post("", data)
export const postFakeForgetPwd = data => post("", data)
export const postFakeRegister = data => post("", data)
export const postJwtRegister = data => post("", data)
export const postFakeProfile = data => post("", data)
export const postJwtProfile = data => post("", data)
