import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_DASHBOARD_DATA,
  GET_TRAVELAGENCY_STAFF_DATA,
  GET_TRAVELAGENCY_DATA,
  GET_TOP_TRAVELAGENCY_DATA,
} from "./actionTypes"
import { apiSuccess, apiFail } from "./actions"

//Include Both Helper File with needed methods
import {
  getDashboardData,
  getTravelAgencyStaffData,
  getTravelAgencyData,
  getTopTravelAgencyData,
} from "../../helpers/fakebackend_helper"

function* dashboardData({ payload: { periodType, customerId, selectedYear } }) {
  try {
    var response
    if (periodType == "monthly") {
      response = yield call(getDashboardData, {
        periodType: periodType,
        customerId: customerId,
        year: selectedYear,
      })
    }
    if (periodType == "yearly") {
      response = yield call(getDashboardData, {
        periodType: periodType,
        customerId: customerId,
      })
    }

    yield put(apiSuccess(GET_DASHBOARD_DATA, response.data))
  } catch (error) {
    yield put(apiFail(GET_DASHBOARD_DATA, error))
  }
}

function* travelAgencyData({ payload: { periodType, selectedYear } }) {
  try {
    const response = yield call(getTravelAgencyData, {
      periodType: periodType,
      year: selectedYear,
    })
    yield put(apiSuccess(GET_TRAVELAGENCY_DATA, response.data))
  } catch (error) {
    yield put(apiFail(GET_TRAVELAGENCY_DATA, error))
  }
}

function* travelAgencyStaff({ payload: { periodType, selectedYear } }) {
  try {
    const response = yield call(getTravelAgencyStaffData, {
      periodType: periodType,
      year: selectedYear,
    })
    yield put(apiSuccess(GET_TRAVELAGENCY_STAFF_DATA, response.data))
  } catch (error) {
    yield put(apiFail(GET_TRAVELAGENCY_STAFF_DATA, error))
  }
}

function* topTravelAgencyData({ payload: { periodType, selectedYear } }) {
  try {
    const response = yield call(getTopTravelAgencyData, {
      periodType: periodType,
      year: selectedYear,
    })
    yield put(apiSuccess(GET_TOP_TRAVELAGENCY_DATA, response.data))
  } catch (error) {
    yield put(apiFail(GET_TOP_TRAVELAGENCY_DATA, error))
  }
}

export function* watchGetChartsData() {
  yield takeEvery(GET_DASHBOARD_DATA, dashboardData)
  yield takeEvery(GET_TRAVELAGENCY_DATA, travelAgencyData)
  yield takeEvery(GET_TRAVELAGENCY_STAFF_DATA, travelAgencyStaff)
  yield takeEvery(GET_TOP_TRAVELAGENCY_DATA, topTravelAgencyData)
}

function* dashboardSaga() {
  yield all([fork(watchGetChartsData)])
}

export default dashboardSaga
