/* MERCHANT DETAIL*/
export const GET_VOUCHER_LIMIT_DETAIL = "GET_VOUCHER_LIMIT_DETAIL"
export const GET_VOUCHER_LIMIT_DETAIL_SUCCESS = "GET_VOUCHER_LIMIT_DETAIL_SUCCESS"
export const GET_VOUCHER_LIMIT_DETAIL_FAIL = "GET_VOUCHER_LIMIT_DETAIL_FAIL"

export const GET_VOUCHER_LIMIT_LIST = "GET_VOUCHER_LIMIT_LIST"
export const GET_VOUCHER_LIMIT_LIST_SUCCESS = "GET_VOUCHER_LIMIT_LIST_SUCCESS"
export const GET_VOUCHER_LIMIT_LIST_FAIL = "GET_VOUCHER_LIMIT_LIST_FAIL"

export const GET_VOUCHER_LIMIT_LIST_BY_ID = "GET_VOUCHER_LIMIT_LIST_BY_ID"
export const GET_VOUCHER_LIMIT_LIST_BY_ID_SUCCESS = "GET_VOUCHER_LIMIT_LIST_BY_ID_SUCCESS"
export const GET_VOUCHER_LIMIT_LIST_BY_ID_FAIL = "GET_VOUCHER_LIMIT_LIST_BY_ID_FAIL"


export const SET_VOUCHER_LIMIT = "SET_VOUCHER_LIMIT"
export const SET_VOUCHER_LIMIT_SUCCESS = "SET_VOUCHER_LIMIT_SUCCESS"
export const SET_VOUCHER_LIMIT_FAIL = "SET_VOUCHER_LIMIT_FAIL"


export const UPDATE_VOUCHER_LIMIT = "UPDATE_VOUCHER_LIMIT"
export const UPDATE_VOUCHER_LIMIT_SUCCESS = "UPDATE_VOUCHER_LIMIT_SUCCESS"
export const UPDATE_VOUCHER_LIMIT_FAIL = "UPDATE_VOUCHER_LIMIT_FAIL"

export const DELETE_VOUCHER_LIMIT = "DELETE_VOUCHER_LIMIT"
export const DELETE_VOUCHER_LIMIT_SUCCESS = "DELETE_VOUCHER_LIMIT_SUCCESS"
export const DELETE_VOUCHER_LIMIT_FAIL = "DELETE_VOUCHER_LIMIT_FAIL"

export const RESET_STATE = "RESET_STATE"
