import React from "react"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../Common/ChartsDynamicColor"
import { Row } from "reactstrap"
import LoadingSpinnerThreeDots from "components/Common/loadingSpinner"

const barchart = ({
  dataColors,
  data = [
    {
      data: [0],
    },
  ],
  titleText = "default",
  categories = [ ],
  isLoading = true,
  plotOptions = {
    bar: {
      horizontal: true,
    },
  },
  dataLabels = {
    enabled: false,
  },
}) => {
  const spineareaChartColors = getChartColorsArray(dataColors)

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: plotOptions,
    dataLabels: {
      enabled: false,
    },
    dataLabels: dataLabels,
    colors: spineareaChartColors,
    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: categories,
    },
  }

  return (
    <React.Fragment>
      {isLoading ? (
        <Row className="spinner-custom mx-auto my-auto pb-3">
          <LoadingSpinnerThreeDots />
        </Row>
      ) : (
        <ReactApexChart
          options={options}
          series={data}
          type="bar"
          height="350"
        />
      )}
    </React.Fragment>
  )
}

export default barchart
