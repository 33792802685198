import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

import LicenseRequest from "./license-request/reducer"
import Dashboard from "./dashboard/reducer"
import VoucherLimit from "./voucher-limit/reducer"
import Customer from "./customer/reducer"
import Notification from "./notification/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  LicenseRequest,
  Dashboard,
  VoucherLimit,
  Customer,
  Notification,
})

export default rootReducer
