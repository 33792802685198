import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  LOGIN_USER,
  LOGIN_MS_USER,
  LOGOUT_USER,
  SOCIAL_LOGIN,
} from "./actionTypes"
import {
  apiError,
  loginSuccess,
  loginMsSuccess,
  logoutUserSuccess,
} from "./actions"

import { postLogin, preLogin } from "../../../helpers/fakebackend_helper"

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postLogin, {
      email: user.email,
      password: user.password,
    })
    localStorage.setItem("authUser", JSON.stringify(response.data))
    yield put(loginSuccess(response))
    window.location.href = "/"
  } catch (error) {
    yield put(apiError(error))
  }
}

function* loginMsUser({ payload: { token, history } }) {
  try {
    const response = yield call(preLogin, token)
    localStorage.setItem("authUser", JSON.stringify(response.data))
    yield put(loginSuccess(response))
    window.location.href = "/"
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")
    history("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGIN_MS_USER, loginMsUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
