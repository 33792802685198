import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import LicenseRequestSaga from "./license-request/saga"
import dashboardSaga from "./dashboard/saga"
import VoucherLimitSaga from "./voucher-limit/saga"
import CustomerSaga from "./customer/saga"
import NotificationSaga from "./notification/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(VoucherLimitSaga),
    fork(LicenseRequestSaga),
    fork(dashboardSaga),
    fork(CustomerSaga),
    fork(NotificationSaga),
  ])
}
