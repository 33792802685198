import error_handler_helper from "helpers/error_handler_helper"
import {
  GET_VOUCHER_LIMIT_DETAIL,
  GET_VOUCHER_LIMIT_DETAIL_FAIL,
  GET_VOUCHER_LIMIT_DETAIL_SUCCESS,
  GET_VOUCHER_LIMIT_LIST,
  GET_VOUCHER_LIMIT_LIST_SUCCESS,
  GET_VOUCHER_LIMIT_LIST_FAIL,
  RESET_STATE,
  GET_VOUCHER_LIMIT_LIST_BY_ID,
  GET_VOUCHER_LIMIT_LIST_BY_ID_SUCCESS,
  GET_VOUCHER_LIMIT_LIST_BY_ID_FAIL,
  SET_VOUCHER_LIMIT,
  SET_VOUCHER_LIMIT_SUCCESS,
  SET_VOUCHER_LIMIT_FAIL,
  UPDATE_VOUCHER_LIMIT,
  UPDATE_VOUCHER_LIMIT_SUCCESS,
  UPDATE_VOUCHER_LIMIT_FAIL,
  DELETE_VOUCHER_LIMIT,
  DELETE_VOUCHER_LIMIT_SUCCESS,
  DELETE_VOUCHER_LIMIT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  voucherLimitList: [],
  voucherLimit: {},
  error: {},
  loading: false,
  updateSuccess: undefined,
  addSuccess: undefined,
  deleteSuccess: undefined,
}

const VoucherLimit = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VOUCHER_LIMIT_DETAIL:
      return {
        ...state,
        voucherLimit: action.payload,
        loading: true,
      }

    case GET_VOUCHER_LIMIT_DETAIL_SUCCESS:
      return {
        ...state,
        voucherLimit: action.payload,
        loading: false,
      }

    case GET_VOUCHER_LIMIT_DETAIL_FAIL:
      return {
        ...state,
        error: error_handler_helper(action.payload),
        loading: false,
      }

    case GET_VOUCHER_LIMIT_LIST:
      return (state = {
        ...state,
        voucherLimitList: action.payload,
        loading: true,
      })

    case GET_VOUCHER_LIMIT_LIST_SUCCESS:
      return {
        ...state,
        voucherLimitList: action.payload,
        loading: false,
      }

    case GET_VOUCHER_LIMIT_LIST_FAIL:
      return {
        ...state,
        error: error_handler_helper(action.payload),
        loading: true,
      }

    case GET_VOUCHER_LIMIT_LIST_BY_ID:
      return (state = {
        ...state,
        voucherLimitList: action.payload,
        loading: true,
      })

    case GET_VOUCHER_LIMIT_LIST_BY_ID_SUCCESS:
      return {
        ...state,
        voucherLimitList: action.payload,
        loading: false,
      }

    case GET_VOUCHER_LIMIT_LIST_BY_ID_FAIL:
      return {
        ...state,
        error: error_handler_helper(action.payload),
        loading: true,
      }

    case SET_VOUCHER_LIMIT:
      return (state = {
        ...state,
        voucherLimit: action.payload,
        loading: true,
        addSuccess: undefined,
      })

    case SET_VOUCHER_LIMIT_SUCCESS:
      return {
        ...state,
        voucherLimit: action.payload,
        loading: false,
        addSuccess: true,
      }

    case SET_VOUCHER_LIMIT_FAIL:
      return {
        ...state,
        error: error_handler_helper(action.payload),
        loading: true,
        addSuccess: false,
      }

    case UPDATE_VOUCHER_LIMIT:
      return (state = {
        ...state,
        voucherLimit: action.payload,
        updateSuccess: undefined,
      })

    case UPDATE_VOUCHER_LIMIT_SUCCESS:
      return {
        ...state,
        voucherLimit: action.payload,
        updateSuccess: true,
      }

    case UPDATE_VOUCHER_LIMIT_FAIL:
      return {
        ...state,
        error: error_handler_helper(action.payload),
        updateSuccess: false,
      }

      case DELETE_VOUCHER_LIMIT:
        return (state = {
          ...state,
          voucherLimit: action.payload,
          deleteSuccess: undefined,
        })
  
      case DELETE_VOUCHER_LIMIT_SUCCESS:
        return {
          ...state,
          voucherLimit: {},
          deleteSuccess: true,
        }
  
      case DELETE_VOUCHER_LIMIT_FAIL:
        return {
          ...state,
          error: error_handler_helper(action.payload),
          deleteSuccess: false,
        }

    case RESET_STATE:
      return {
        ...state,
        // voucherLimitList: [],
        // voucherLimit: {},
        error: {},
        loading: false,
        updateSuccess: undefined,
        addSuccess: undefined,
        deleteSuccess: undefined,
      }

    default:
      return state
  }
}

export default VoucherLimit
