import {
  API_SUCCESS,
  API_FAIL,
  GET_DASHBOARD_DATA,
  GET_TRAVELAGENCY_STAFF_DATA,
  GET_TRAVELAGENCY_DATA,
  GET_TOP_TRAVELAGENCY_DATA,
} from "./actionTypes"

export const apiSuccess = (actionType, data) => ({
  type: API_SUCCESS,
  payload: { actionType, data },
})

export const apiFail = (actionType, error) => ({
  type: API_FAIL,
  payload: { actionType, error },
})

// charts data
export const getDashboardData = (periodType, customerId, selectedYear) => ({
  type: GET_DASHBOARD_DATA,
  payload: { periodType, customerId, selectedYear },
})

export const getTravelAgencyStaffData = (periodType, selectedYear) => ({
  type: GET_TRAVELAGENCY_STAFF_DATA,
  payload: { periodType, selectedYear },
})
export const getTopTravelAgencyData = (periodType, selectedYear) => ({
  type: GET_TOP_TRAVELAGENCY_DATA,
  payload: { periodType, selectedYear },
})

export const getTravelAgencyData = (periodType, selectedYear) => ({
  type: GET_TRAVELAGENCY_DATA,
  payload: { periodType, selectedYear },
})
