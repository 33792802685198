import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import { Card, Row, CardBody, Col, Table } from "reactstrap"
import { statusPill } from "common/helpers/statusPill"
import  dateFormat  from "common/helpers/dateFormat"

const Transaction = props => {
  const { license } = props

  const [total, setTotal] = useState()
  useEffect(() => {
    const sum = license?.invoiceDetails?.services?.reduce(
      (accumulator, currentValue) => accumulator + currentValue.amount,
      0
    )

  
    setTotal(sum)
  }, [license])

  useEffect(() => {}, [total])

  return (
    <React.Fragment>
      <Row>
      

        <Col lg ={5}>
          <Card>
            <CardBody>
              <h5 className="fw-semibold">
                
                {statusPill(license?.status, license?.status, true)}
              </h5>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardBody>
              <h5 className="fw-semibold">
                {total} {license?.currency}{" "}
               
              </h5>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row >
        <Col xl={5}>
          <Card style={{height:"95%"}}>
            <CardBody>
              <h5 className="fw-semibold">Transaction Details</h5>

              <div className="table-responsive">
                <table className="table">
                  <tbody>
                    <tr>
                      <th scope="col">GRN</th>
                      <td scope="col">{license?.grn}</td>
                    </tr>
                    <tr>
                      <th scope="col"> Unique Id</th>
                      <td scope="col">{license?.grn}</td>
                    </tr>
                    <tr>
                      <th scope="row">Transaction Date</th>
                      <td scope="col">{dateFormat(license?.transactionDate)}</td>
                    </tr>
                    <tr>
                      <th scope="row">Processed Date</th>
                      <td scope="col">{dateFormat(license?.emailProcessedDate)}</td>
                    </tr>
                    <tr>
                      <th scope="row">Transaction Id</th>
                      <td scope="col">{license?.paymentInfo?.transactionId}</td>
                    </tr>
                    <tr>
                      <th scope="row">Transaction Fee</th>
                      <td scope="col">{license?.paymentInfo?.transactionFee}</td>
                    </tr>
                    <tr>
                      <th scope="row">Payer Name</th>
                      <td scope="col">{license?.paymentInfo?.payerName}</td>
                    </tr>
                    <tr>
                      <th scope="row">Payer Email</th>
                      <td scope="col">{license?.paymentInfo?.payerEmail}</td>
                    </tr>
                    <tr>
                      <th scope="row">Payer Address</th>
                      <td scope="col">{license?.paymentInfo?.payerAddress?.street} {license?.paymentInfo?.payerAddress?.city}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={7}>
          <Card>
            <CardBody>
              <h5 className="fw-semibold"> Services </h5>

              <div className="table-responsive">
                <table className="table">
                  <tbody>
                    {license?.invoiceDetails?.services?.map((item, i) => (
                      <tr key={i}>
                        <th scope="col">
                          {item.description}
                          <br></br> <p>{item.item}</p>{" "}
                        </th>
                        <td scope="col">
                          {item.amount} {" "} {item.currencySymbol}
                        </td>
                      </tr>
                    ))}

                    <tr>
                      <th scope="col"> Amount Due</th>
                      <td scope="col">{total}{" "} {license?.currency}</td>
                    </tr>
                    
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>    
        <Col >
          <Card>
            <CardBody>
            <h5 className="fw-semibold">Agency Info</h5>

            <div className="table-responsive">
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <th scope="col">Agency Name</th>
                                        <td scope="col">{license?.travelAgencyName}</td>
                                    </tr>
                                    <tr>
                                    <th scope="col"> Office Id</th>
                                        <td scope="col">{license?.officeId}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Staff Name</th>
                                        <td scope="col">{license?.staffName}</td>
                                    </tr>
                                   
                                </tbody>
                            </table>
                        </div> 
            </CardBody>
          </Card>
        </Col>
        </Row> 
    </React.Fragment>
  )
}

Transaction.propTypes = {}

export default Transaction
