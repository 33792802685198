import { call, put, takeEvery, takeLatest } from "redux-saga/effects"


import {
  GET_VOUCHER_LIMIT_DETAIL,
  GET_VOUCHER_LIMIT_LIST,
  GET_VOUCHER_LIMIT_LIST_BY_ID,
  SET_VOUCHER_LIMIT,
  UPDATE_VOUCHER_LIMIT,
  DELETE_VOUCHER_LIMIT,
} from "./actionTypes"

import {
  getVoucherLimitDetailSuccess,
  getVoucherLimitDetailFail,
  getVoucherLimitListSuccess,
  getVoucherLimitListFail,
  getVoucherLimitListByIdSuccess,
  getVoucherLimitListByIdFail,
  setVoucherLimitSuccess,
  setVoucherLimitFail,
  updateVoucherLimitSuccess,
  updateVoucherLimitFail,
  deleteVoucherLimitSuccess,
  deleteVoucherLimitFail,

  returnStateTransaction,

} from "./actions"

//Include Both Helper File with needed methods
import {
 getVoucherLimitDetail,
 getVoucherLimitList,
 getVoucherLimitListById,
 setVoucherLimit,
 updateVoucherLimit,
 deleteVoucherLimit,
} from "helpers/fakebackend_helper"

function* _getVoucherLimitDetail({ id }) {
  try {
    const response = yield call(getVoucherLimitDetail, id)
    yield put(getVoucherLimitDetailSuccess(response.data))
  } catch (error) {
    yield put(getVoucherLimitDetailFail(error?.message))
  }
}

function* _getVoucherLimitList() {
  try {
    const response = yield call(getVoucherLimitList)
    yield put(getVoucherLimitListSuccess(response.data))
  } catch (error) {
    yield put(getVoucherLimitListFail(error?.message))
  }
}


function* _getVoucherLimitListById( {filter} ) {
  try {

    const response = yield call(getVoucherLimitListById, filter)
    yield put(getVoucherLimitListByIdSuccess(response.data))
  } catch (error) {
    yield put(getVoucherLimitListByIdFail(error))
  }
}

function* _setVoucherLimit({data}) {


  try {
    const response = yield call(setVoucherLimit,data)
  
    yield put(setVoucherLimitSuccess(response.data))
  } catch (error) {
    yield put(setVoucherLimitFail(error))
  }
}

function* _updateVoucherLimit({data}) {


  try {
    const response = yield call(updateVoucherLimit,data)
  
    yield put(updateVoucherLimitSuccess(response.data))
  } catch (error) {
    yield put(updateVoucherLimitFail(error))
  }
}

function* _deleteVoucherLimit({data}) {


  try {
    const response = yield call(deleteVoucherLimit,data)
  
    yield put(deleteVoucherLimitSuccess(response.data))
  } catch (error) {
    yield put(deleteVoucherLimitFail(error))
  }
}




function* VoucherLimitSaga() {
  yield takeEvery(GET_VOUCHER_LIMIT_DETAIL, _getVoucherLimitDetail)
  yield takeEvery(GET_VOUCHER_LIMIT_LIST, _getVoucherLimitList)
  yield takeEvery(GET_VOUCHER_LIMIT_LIST_BY_ID, _getVoucherLimitListById)
  yield takeEvery(SET_VOUCHER_LIMIT, _setVoucherLimit)
  yield takeEvery(UPDATE_VOUCHER_LIMIT, _updateVoucherLimit)
  yield takeEvery(DELETE_VOUCHER_LIMIT, _deleteVoucherLimit)
}

export default VoucherLimitSaga
