import React from "react"
import { Badge, Button } from "reactstrap"

export const statusPill = (cellContent, value, pill) => {
  let colorStatus = ""
  let colorText = ""

  if (cellContent === "PAID" || cellContent === true) {
    colorStatus = "success"
    colorText = "green"
  } else if (cellContent === "UNPAID" || cellContent === false) {
    colorStatus = "danger"
    colorText = "red"
  } else {
    colorStatus = "warning"
    colorText = "yellow"
  }

  const pillClass = pill === true ? " badge-pill" : "";

  return (
    <Badge
      className={`font-size-13 badge-soft-${colorStatus}${pillClass}`}
      color={colorText}
    >
      {value}
    </Badge>
  );


}
