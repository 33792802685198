import React, { Fragment, useState, useEffect } from "react"
import PropTypes, { object } from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import { Table, Row, Col, Button, Input, CardBody, Card } from "reactstrap"
import { Filter, DefaultColumnFilter } from "../../components/Common/filters"
import JobListGlobalFilter from "../../components/Common/GlobalSearchFilter"
import { CSVLink, CSVDownload } from "react-csv"
import SearchFilter from "./SearchFilter"
import authDetails from "../../helpers/auth-details/auth-details"
import numberFormat from "common/helpers/numberFormat"

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <React.Fragment>
      <Col md={4}>
        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value)
                  onChange(e.target.value)
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`${count} records...`}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search-alt search-icon"></i>
          </div>
        </div>
      </Col>
      {/* {isJobListGlobalFilter && <SearchFilter />} */}
    </React.Fragment>
  )
}

const TableContainer = ({
  columns,
  data,
  voucherLimits,
  isGlobalFilter,
  isJobListGlobalFilter,
  isAddOptions,
  isAddUserList,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  className,
  customPageSizeOptions,
}) => {
  const {
    getTableProps,
    rows,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      voucherLimits,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        // sortBy: [
        //   {
        //     desc: true,
        //   },
        // ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )
  const [user, setUser] = useState(authDetails)
  const [count, setCount] = useState()
  const [revenue, setRevenue] = useState()
  const [remainingLimit, setRemainingLimit] = useState()
  const [comission, setCommission] = useState()
  const [reportType, setReportType] = useState("")
  const [csv, setCsv] = useState([])
  const [downloaded, setDownloaded] = useState(false)
  const [totalVoucherOnly, setTotalVoucherOnly] = useState()

  useEffect(() => {
    let sum = rows?.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.values.totalAmount,
      0
    )

    if (user.customerID === null && user.email.includes("@amadeus.qa")) {
      const commissionRows = rows.flatMap(({ original }) =>
        original.invoiceDetails?.services?.map(
          ({ commission, currencySymbol }) => ({
            commission,
            currencySymbol,
          })
        )
      )

      let sumOfCommission = commissionRows
        .filter(i => i !== undefined)
        ?.reduce(
          (accumulator, currentValue) => accumulator + currentValue?.commission,
          0
        )

      setCommission(sumOfCommission)
      setRemainingLimit(0)
    } else {
      let sumVouchersOnly = rows
        .filter(i => i.original.paymentInfo?.transactionId == "")
        .reduce(
          (accumulator, currentValue) =>
            accumulator + currentValue.values.totalAmount,
          0
        )

      let sumVoucherLimits = voucherLimits?.reduce(
        (accumulator, currentValue) => accumulator + currentValue.limit,
        0
      )

      if (sumVoucherLimits === 0) {
        setRemainingLimit(0)
      } else {
        let remainingBalance = sumVoucherLimits - sumVouchersOnly
        setRemainingLimit(remainingBalance)
      }
    }

    setCount(rows.length)
    setRevenue(sum)
  }, [rows, voucherLimits])

  useEffect(() => {}, [reportType])

  useEffect(() => {}, [csv])

  useEffect(() => {
    if (downloaded) {
      setCsv([]) // Reset csv state
      setDownloaded(false) // Reset the downloaded state
    }
  }, [downloaded])

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0

    gotoPage(page)
  }

  const handleReportDownload = (event, done) => {
    if (reportType === "row") {
      const selectedTransactions = rows.map(x => ({
        id: x.original._id,
        GRN: x.original.grn,
        UniqueD: x.original.uniqueID,
        ProcessedDate: x.original.emailProcessedDate,
        TransactionsDate: x.original.transactionDate,
        TravelAgencyName: x.original.travelAgencyName,
        OfficeId: x.original.officeId,
        StaffName: x.original.staffName,
        FirstName: x.original.customerProfile.firstName,
        LastName: x.original.customerProfile.lastName,
        Email: x.original.customerProfile.emailAddress,
        Nationality: x.original.customerProfile.drivingLicenseNationality,
        // MobileNumber: x.original.mobileNumber,
        BirthDate: x.original.customerProfile.birthDate,
        DrivingLicenseNumber: x.original.customerProfile.drivingLicenseNumber,
        DrivingLicenseIssue: x.original.customerProfile.drivingLicenseIssueDate,
        DrivingLicenseExpiry:
          x.original.customerProfile.drivingLicenseExpiryDate,
        DrivingLicenseClass: x.original.customerProfile.drivingLicenseClass,
        // InvoiceUrl: x.original.invoiceUrl,
        Amount: x.original.totalAmount,
        Currency: x.original.currency,
        Status: x.original.status,
      }))

      setCsv(selectedTransactions)

      done(true)
    } else {
      const mappedRows = rows
        .filter(i => i.original.invoiceDetails.services !== null)
        .flatMap(({ original }) =>
          original.invoiceDetails?.services?.map(
            ({ description, item, options, amount, currencySymbol }) => ({
              id: original._id,
              grn: original.grn,
              description,
              options,
              item,
              amount,
              currencySymbol,
            })
          )
        )

      done(true)

      setCsv(mappedRows)
    }
  }

  return (
    <Fragment>
      <Row>
        <Col>
          <Card className="mini-stats-wid">
            <CardBody>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <p className="text-muted fw-medium">Total Transactions</p>
                  <h4 className="mb-0">{count}</h4>
                </div>
                <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                  <span className="avatar-title rounded-circle bg-primary">
                    <i className={"bx bx-id-card font-size-24"}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card className="mini-stats-wid">
            <CardBody>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <p className="text-muted fw-medium">Total Sales</p>
                  <h4 className="mb-0">{numberFormat(revenue)} </h4>
                </div>
                <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                  <span className="avatar-title rounded-circle bg-primary">
                    <i className={"bx bx-money font-size-24"}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card className="mini-stats-wid">
            <CardBody>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <p className="text-muted fw-medium">
                    Remaining Voucher Limit
                  </p>
                  <h4 className="mb-0">{numberFormat(remainingLimit)}</h4>
                </div>
                <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                  <span className="avatar-title rounded-circle bg-primary">
                    <i className={"bx bx-id-card font-size-24"}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        {user.customerID === null && user.email.includes("@amadeus.qa") && (
          <Col>
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">ACO Commission</p>
                    <h4 className="mb-0">{numberFormat(comission)}</h4>
                  </div>
                  <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                    <span className="avatar-title rounded-circle bg-primary">
                      <i className={"bx bx-money font-size-24"}></i>
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        )}
        <Col xl={3}>
          <Card className="mini-stats-wid">
            <CardBody>
              <div className="d-flex">
                <div className="flex-grow-1">
                  <div className="d-flex justify-content-center">
                    <div className="p-2">
                      <select
                        className="form-select"
                        value={reportType}
                        onChange={e => setReportType(e.target.value)}
                      >
                        <option value="" defaultValue hidden="hidden">
                          Choose Report to Download
                        </option>
                        <option key="row" value="row">
                          Row Report - {count} transactions
                        </option>
                        <option key="detailed" value="detailed">
                          Detailed Report - {count} transactions
                        </option>
                      </select>
                    </div>
                    <div className="p-2">
                      {csv && reportType !== "" && (
                        <Button type="button" color="success">
                          <CSVLink
                            data={csv}
                            filename={"idl-report.csv"}
                            asyncOnClick={true}
                            onClick={event =>
                              handleReportDownload(event, setDownloaded)
                            }
                          >
                            <span style={{ color: "white" }}>
                              <i className="mdi mdi-arrow-down me-1" />{" "}
                              {reportType}
                            </span>
                          </CSVLink>
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Card>
        <CardBody>
          <Row>
            <Col md={customPageSizeOptions ? 2 : 1}>
              <select
                className="form-select"
                value={pageSize}
                onChange={onChangeInSelect}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </Col>
            {isGlobalFilter && (
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                isJobListGlobalFilter={isJobListGlobalFilter}
              />
            )}
            {isAddOptions && (
              <Col sm="7">
                <div className="text-sm-end">
                  <Button
                    type="button"
                    color="success"
                    className="btn-rounded  mb-2 me-2"
                    onClick={handleOrderClicks}
                  >
                    <i className="mdi mdi-plus me-1" />
                    Add New Order
                  </Button>
                </div>
              </Col>
            )}
            {isAddUserList && (
              <Col sm="7">
                <div className="text-sm-end">
                  <Button
                    type="button"
                    color="primary"
                    className="btn mb-2 me-2"
                    onClick={handleUserClick}
                  >
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    Create New User
                  </Button>
                </div>
              </Col>
            )}
            {isAddCustList && (
              <Col sm="7">
                <div className="text-sm-end">
                  <Button
                    type="button"
                    color="success"
                    className="btn-rounded mb-2 me-2"
                    onClick={handleCustomerClick}
                  >
                    <i className="mdi mdi-plus me-1" />
                    New Customers
                  </Button>
                </div>
              </Col>
            )}
          </Row>
          <div className="table-responsive react-table">
            <Table bordered hover {...getTableProps()} className={className}>
              <thead className="table-light table-nowrap">
                {headerGroups.map(headerGroup => (
                  <tr
                    key={headerGroup.id}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map(column => (
                      <th key={column.id}>
                        <div
                          className="mb-2"
                          {...column.getSortByToggleProps()}
                        >
                          {column.render("Header")}
                          {generateSortingIndicator(column)}
                        </div>
                        <Filter column={column} />
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {page.map(row => {
                  prepareRow(row)

                  return (
                    <Fragment key={row.getRowProps().key}>
                      <tr>
                        {row.cells.map(cell => {
                          return (
                            <td key={cell.id} {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          )
                        })}
                      </tr>
                    </Fragment>
                  )
                })}
              </tbody>
            </Table>
          </div>

          <Row className="justify-content-md-end justify-content-center align-items-center">
            <Col className="col-md-auto">
              <div className="d-flex gap-1">
                <Button
                  color="primary"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  {"<<"}
                </Button>
                <Button
                  color="primary"
                  onClick={previousPage}
                  disabled={!canPreviousPage}
                >
                  {"<"}
                </Button>
              </div>
            </Col>
            <Col className="col-md-auto d-none d-md-block">
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </Col>
            <Col className="col-md-auto">
              <Input
                type="number"
                min={1}
                style={{ width: 70 }}
                max={pageOptions.length}
                defaultValue={pageIndex + 1}
                onChange={onChangeInInput}
              />
            </Col>

            <Col className="col-md-auto">
              <div className="d-flex gap-1">
                <Button
                  color="primary"
                  onClick={nextPage}
                  disabled={!canNextPage}
                >
                  {">"}
                </Button>
                <Button
                  color="primary"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">>"}
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default TableContainer
