import {
  API_SUCCESS,
  API_FAIL,
  GET_DASHBOARD_DATA,
  GET_TRAVELAGENCY_DATA,
  GET_TRAVELAGENCY_STAFF_DATA,
  GET_TOP_TRAVELAGENCY_DATA,
} from "./actionTypes"

const INIT_STATE = {
  dashboardData: [],
  travelAgencyData: [],
  travelAgencyStaffData: [],
  topTravelAgencyData: [],
}

const Dashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_SUCCESS:
      switch (action.payload.actionType) {
        case GET_DASHBOARD_DATA:
          return {
            ...state,
            dashboardData: action.payload,
          }
        case GET_TRAVELAGENCY_DATA:
          return {
            ...state,
            travelAgencyData: action.payload,
          }
        case GET_TRAVELAGENCY_STAFF_DATA:
          return {
            ...state,
            travelAgencyStaffData: action.payload,
          }
        case GET_TOP_TRAVELAGENCY_DATA:
          return {
            ...state,
            topTravelAgencyData: action.payload,
          }
        default:
          return state
      }
    case API_FAIL:
      switch (action.payload.actionType) {
        case GET_DASHBOARD_DATA:
          return {
            ...state,
            dashboardData: action.payload.error,
          }
        case GET_TRAVELAGENCY_DATA:
          return {
            ...state,
            travelAgencyDataError: action.payload,
          }
        case GET_TRAVELAGENCY_STAFF_DATA:
          return {
            ...state,
            travelAgencyStaffDataError: action.payload,
          }
        case GET_TOP_TRAVELAGENCY_DATA:
          return {
            ...state,
            topTravelAgencyDataError: action.payload,
          }
        default:
          return state
      }
    default:
      return state
  }
}

export default Dashboard
