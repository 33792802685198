import {
  GET_VOUCHER_LIMIT_DETAIL,
  GET_VOUCHER_LIMIT_DETAIL_FAIL,
  GET_VOUCHER_LIMIT_DETAIL_SUCCESS,
  GET_VOUCHER_LIMIT_LIST,
  GET_VOUCHER_LIMIT_LIST_SUCCESS,
  GET_VOUCHER_LIMIT_LIST_FAIL,
  GET_VOUCHER_LIMIT_LIST_BY_ID,
  GET_VOUCHER_LIMIT_LIST_BY_ID_SUCCESS,
  GET_VOUCHER_LIMIT_LIST_BY_ID_FAIL,
  SET_VOUCHER_LIMIT,
  SET_VOUCHER_LIMIT_SUCCESS,
  SET_VOUCHER_LIMIT_FAIL,
  UPDATE_VOUCHER_LIMIT,
  UPDATE_VOUCHER_LIMIT_SUCCESS,
  UPDATE_VOUCHER_LIMIT_FAIL,
  DELETE_VOUCHER_LIMIT,
  DELETE_VOUCHER_LIMIT_SUCCESS,
  DELETE_VOUCHER_LIMIT_FAIL,

  RESET_STATE,
} from "./actionTypes"

export const getVoucherLimitList = () => ({
  type: GET_VOUCHER_LIMIT_LIST,
})

export const getVoucherLimitListSuccess = data => ({
  type: GET_VOUCHER_LIMIT_LIST_SUCCESS,
  payload: data,
})

export const getVoucherLimitListFail = error => ({
  type: GET_VOUCHER_LIMIT_LIST_FAIL,
  payload: error,
})



export const getVoucherLimitDetail = id => ({
  type: GET_VOUCHER_LIMIT_DETAIL,
  id,
})

export const getVoucherLimitDetailSuccess = data => ({
  type: GET_VOUCHER_LIMIT_DETAIL_SUCCESS,
  payload: data,
})

export const getVoucherLimitDetailFail = error => ({
  type: GET_VOUCHER_LIMIT_DETAIL_FAIL,
  payload: error,
})

export const getVoucherLimitListById = filter => ({
  type: GET_VOUCHER_LIMIT_LIST_BY_ID,
  filter
})

export const getVoucherLimitListByIdSuccess = data => ({
  type: GET_VOUCHER_LIMIT_LIST_BY_ID_SUCCESS,
  payload: data,
})

export const getVoucherLimitListByIdFail = error => ({
  type: GET_VOUCHER_LIMIT_LIST_BY_ID_FAIL,
  payload: error,
})


export const setVoucherLimit = data => ({
  type: SET_VOUCHER_LIMIT,
  data
})

export const setVoucherLimitSuccess = data => ({
  type: SET_VOUCHER_LIMIT_SUCCESS,
  payload: data,
})

export const setVoucherLimitFail = error => ({
  type: SET_VOUCHER_LIMIT_FAIL,
  payload: error,
})

export const updateVoucherLimit = data => ({
  type: UPDATE_VOUCHER_LIMIT,
  data
})

export const updateVoucherLimitSuccess = data => ({
  type: UPDATE_VOUCHER_LIMIT_SUCCESS,
  payload: data,
})

export const updateVoucherLimitFail = error => ({
  type: UPDATE_VOUCHER_LIMIT_FAIL,
  payload: error,
})

export const deleteVoucherLimit = data => ({
  type: DELETE_VOUCHER_LIMIT,
  data
})

export const deleteVoucherLimitSuccess = data => ({
  type: DELETE_VOUCHER_LIMIT_SUCCESS,
  payload: data,
})

export const deleteVoucherLimitFail = error => ({
  type: DELETE_VOUCHER_LIMIT_FAIL,
  payload: error,
})

export const resetState = () => ({
  type: RESET_STATE,
});

