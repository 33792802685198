import React, { useState, useEffect, useMemo } from "react"
import {
  CustomerId,
  TravelAgencyName,
  Emails,
  LastNotified,
} from "./NotificationListColumn"
import LoadingSpinner from "common/helpers/loadingSpinner"
import { useFormik } from "formik"
import * as Yup from "yup"
import {
  UncontrolledTooltip,
  Row,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import { Link } from "react-router-dom"
import TableContainer from "./TableContainer"

function NotificationList(props) {
  const {
    notificationsList,
    onSetNotification,
    customersList,
    onUpdateNotification,
    onDeleteNotification,
    user,
  } = props
  const columnsAdmin = useMemo(
    () => [
      {
        Header: "Customer Id",
        accessor: "customerId",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <CustomerId {...cellProps} />
        },
      },
      {
        Header: "Customer Name",
        accessor: "travelAgencyName",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <TravelAgencyName {...cellProps} />
        },
      },
      {
        Header: "Last Notified",
        accessor: "lastNotified",
        disableFilters: true,
        Cell: cellProps => {
          return <LastNotified {...cellProps} />
        },
      },
      {
        Header: "Emails",
        accessor: "emails",
        disableFilters: true,
        Cell: cellProps => {
          return <Emails {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "_id",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-info"
                  onClick={() => handleNotificationEdit(cellProps.row.original)}
                >
                  <i className="mdi mdi-pencil-outline" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </li>

              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => handleDelete(cellProps.row.original)}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )
  const [columns, setColumns] = useState(columnsAdmin)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [notification, setNotification] = useState(null)
  const toggle = () => {
    if (modal) {
      setModal(false)
      validation.resetForm()
    } else {
      setModal(true)
    }
  }
  const initialValues = {
    customerId: (notification && notification.customerId) || "",
    travelAgencyName: (notification && notification.travelAgencyName) || "",
    emails: (notification && notification.emails) || "",
    lastNotified: (notification && notification.lastNotified) || "",
  }
  const validationSchema = Yup.object({
    customerId: Yup.string().required("Please select a travel agency"),
    travelAgencyName: Yup.string().required("Please select a travel agency"),
    emails: Yup.string()
      .matches(
        /^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})(,[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})*$/i,
        "Email(s) is invalid."
      )
      .required("Please enter emails."),
  })

  useEffect(() => {
    if (notification) {
      validation.setValues({
        customerId: (notification && notification.customerId) || "",
        travelAgencyName: (notification && notification.travelAgencyName) || "",
        emails: (notification && notification.emails) || [],
        lastNotified: (notification && notification.lastNotified) || "",
      })
    }
  }, [notification])

  const onSubmit = async values => {
    if (isEdit) {
      const updateNotification = {
        _id: notification ? notification._id : 0,
        customerId: validation.values.customerId,
        travelAgencyName: validation.values.travelAgencyName,
        emails: [validation.values.emails],
        lastNotified: validation.values.lastNotified,
      }

      await onUpdateNotification(updateNotification)
      validation.resetForm()
    } else {
      const newNotification = {
        _id: "",
        customerId: validation.values.customerId,
        travelAgencyName: validation.values.travelAgencyName,
        emails: [validation.values.emails],
        lastNotified: new Date(),
      }

      await onSetNotification(newNotification)
      validation.resetForm()
    }
    toggle()
  }
  const validation = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })
  useEffect(() => {
    if (validation.values.travelAgencyName) {
      customersList.map(customer => {
        if (customer.customerName === validation.values.travelAgencyName) {
          validation.values.customerId = customer.customerID
        }
      })
    }
  }, [validation.values])

  const handleNotificationEdit = arg => {
    setNotification({
      _id: arg._id,
      customerId: arg.customerId,
      travelAgencyName: arg.travelAgencyName,
      emails: arg.emails.toString(),
      lastNotified: arg.lastNotified,
    })
    setIsEdit(true)
    toggle()
  }

  const handleDelete = async arg => {
    await onDeleteNotification(arg._id)
  }

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop="static">
        <ModalHeader toggle={toggle} tag="h4">
          {!!isEdit ? "Edit Notification Emails" : "Add Notification Emails"}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={validation.handleSubmit}>
            <Row>
              <Col className="col-12">
                <div className="mb-3">
                  <Label className="form-label">Customer Name</Label>
                  <Input
                    name="travelAgencyName"
                    type="select"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.travelAgencyName || ""}
                    invalid={
                      validation.touched.travelAgencyName &&
                      validation.errors.travelAgencyName
                        ? true
                        : false
                    }
                  >
                    <option value="" defaultValue hidden>
                      Select
                    </option>
                    {customersList.map((customer, index) => {
                      // validation.values.customerId = customer.customerID
                      return (
                        <option value={customer.customerName} key={index}>
                          {customer.customerName}
                        </option>
                      )
                    })}
                  </Input>
                  {validation.touched.travelAgencyName &&
                  validation.errors.travelAgencyName ? (
                    <FormFeedback status="invalid">
                      {validation.errors.travelAgencyName}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">
                    Emails{" "}
                    <small>
                      Seperate emails with <b>comma {"(,)"}</b>
                    </small>
                  </Label>
                  <Input
                    name="emails"
                    placeholder="Enter emails"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.emails || ""}
                    invalid={
                      validation.touched.emails && validation.errors.emails
                        ? true
                        : false
                    }
                  />
                  {validation.touched.emails && validation.errors.emails ? (
                    <FormFeedback type="invalid">
                      {validation.errors.emails}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user">
                    Submit
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      <Row>
        <Col lg="12">
          {user[0].roleID === 1 ? (
            <TableContainer
              columns={columns}
              data={notificationsList}
              isGlobalFilter={true}
              isAddOptions={true}
              //isAddNotification={setModal}
              toggle={toggle}
              isSearchFilter={false}
              customPageSize={50}
            />
          ) : (
            <LoadingSpinner />
          )}
        </Col>
      </Row>
    </>
  )
}

export default NotificationList
