import React, { useState, useEffect } from "react"
import {
  getNotificationList,
  getNotificationDetail,
  setNotification,
  getCustomerList,
  updateNotification,
  restartStateNotifications,
  deleteNotification,
} from "store/actions"
import { connect } from "react-redux"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import LoadingSpinner from "common/helpers/loadingSpinner"
import NotificationList from "./NotificationList"
import { Container, Row } from "reactstrap"
import authDetails from "../../helpers/auth-details/auth-details"

function index(props) {
  const {
    onGetNotificationList,
    notificationList,
    onSetNotification,
    notificationDetail,
    setSuccess,
    loading,
    onGetCustomerList,
    customerList,
    onUpdateNotification,
    updateSuccess,
    onRestartStateNotification,
    onDeleteNotification,
    deleteSuccess,
  } = props

  const [notificationsList, setNotificationsList] = useState([])
  const [customersList, setCustomersList] = useState([])
  const user = useState(authDetails)

  useEffect(() => {
    if (user[0].roleID === 1) {
      onGetNotificationList()
      onGetCustomerList()
    }
  }, [])
  useEffect(() => {
    if (notificationList) {
      setNotificationsList(notificationList)
    }
  }, [notificationList])
  useEffect(() => {}, [notificationsList])
  useEffect(() => {
    if (customerList) {
      setCustomersList(customerList)
    }
  }, [customerList])
  useEffect(() => {}, [customersList])

  toastr.options = {
    positionClass: "toast-top-right",
    timeOut: 5000,
    extendedTimeOut: 1000,
    closeButton: true,
    debug: true,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 1000,
  }

  function showToastSuccess(message) {
    toastr.success(`Successfuly ${message}`, "")
    handleReload()
  }

  function showToastError(message) {
    toastr.error(`${message} , Something went wrong`, "")
    handleReload()
  }
  const handleReload = async () => {
    await sleep(1000)
    window.location.reload()
  }

  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {updateSuccess && showToastSuccess("Updated")}
            {updateSuccess === false && showToastError("Not Updated")}

            {setSuccess === true && showToastSuccess("Added")}
            {setSuccess === false && showToastError("Not Added")}

            {deleteSuccess && showToastSuccess("Deleted")}
            {deleteSuccess === false && showToastError("Not Deleted")}

            {loading ? (
              <LoadingSpinner />
            ) : (
              <>
                <Row>
                  {notificationsList && (
                    <NotificationList
                      user={user}
                      notificationsList={notificationsList}
                      onSetNotification={onSetNotification}
                      customersList={customersList}
                      onUpdateNotification={onUpdateNotification}
                      onDeleteNotification={onDeleteNotification}
                    />
                  )}
                </Row>
              </>
            )}
          </Container>
        </div>
      </React.Fragment>
    </>
  )
}

const mapStateToProps = state => ({
  notificationList: state.Notification.notificationList,
  notificationDetail: state.Notification.notificationDetail,
  loading: state.Notification.loading,
  customerList: state.Customer.customerList,
  setSuccess: state.Notification.setSuccess,
  updateSuccess: state.Notification.updateSuccess,
  deleteSuccess: state.Notification.deleteSuccess,
})

const mapDispatchToProps = dispatch => ({
  onGetNotificationList: () => dispatch(getNotificationList()),
  onSetNotification: data => dispatch(setNotification(data)),
  onGetCustomerList: () => dispatch(getCustomerList()),
  onGetNotificationDetail: id => dispatch(getNotificationDetail(id)),
  onUpdateNotification: data => dispatch(updateNotification(data)),
  onRestartStateNotification: () => dispatch(restartStateNotifications()),
  onDeleteNotification: id => dispatch(deleteNotification(id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(index)
