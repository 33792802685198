import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  CardHeader,
  Input,
  Label,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import classNames from "classnames"
import StackedColumnChart from "../../components/Charts/StackedColumnChart"
import { withTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import TravelAgencyView from "./Views/TravelAgencyView"
import Select from "react-select"
import AdminView from "./Views/AdminView"
import authHeader from "helpers/jwt-token-access/auth-token-header"
import * as jose from "jose"
import { currentYear } from "common/helpers/dateFormat"
const Dashboard = props => {
  const [periodType, setPeriodType] = useState("monthly")
  const [totalRevenue, setTotalRevenue] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [selectedYear, setSelecetedYear] = useState(currentYear())

  const token = jose.decodeJwt(authHeader().Authorization)

  useEffect(() => {}, [selectedYear])

  document.title = "Dashboard | Amadeus Qatar - Morningstars"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}

          {token.role === 1 ? (
            <Breadcrumbs
              title={props.t("Dashboards")}
              breadcrumbItem={props.t(
                "OverAll Market Productivity Insight " + selectedYear
              )}
            />
          ) : (
            <Breadcrumbs
              title={props.t("Dashboards")}
              breadcrumbItem={props.t(
                "IDL Productivity Insight " + selectedYear
              )}
            />
          )}
          <Row className="mb-3">
            <Col md="1">
              <Label className="form-label">Select Year</Label>
              <Input
                name="selectedYear"
                type="select"
                onChange={e => setSelecetedYear(e.target.value)}
                onBlur={e => setSelecetedYear(e.target.value)}
                value={selectedYear}
              >
                <option value={currentYear()} defaultValue hidden>
                  {currentYear()}
                </option>
                <option value={2024}>2024</option>
                <option value={2023}>2023</option>
              </Input>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              {token.role == 1 ? (
                <AdminView
                  periodType={periodType}
                  selectedYear={selectedYear}
                />
              ) : (
                <TravelAgencyView selectedYear={selectedYear} />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Dashboard)
