import error_handler_helper from "helpers/error_handler_helper"
import {
  GET_NOTIFICATION_LIST,
  GET_NOTIFICATION_LIST_SUCCESS,
  GET_NOTIFICATION_LIST_FAIL,
  GET_NOTIFICATION_DETAIL,
  GET_NOTIFICATION_DETAIL_SUCCESS,
  GET_NOTIFICATION_DETAIL_FAIL,
  SET_NOTIFICATION,
  SET_NOTIFICATION_SUCCESS,
  SET_NOTIFICATION_FAIL,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAIL,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAIL,
  RESTART_STATE_NOTIFICATIONS,
} from "./actionTypes"

const INIT_STATE = {
  notificationList: [],
  notificationDetail: {},
  error: {},
  loading: false,
  updateSuccess: undefined,
  setSuccess: undefined,
  deleteSuccess: undefined,
}

const Notification = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NOTIFICATION_LIST:
      return {
        ...state,
        loading: true,
      }

    case GET_NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        notificationList: action.payload,
        loading: false,
      }

    case GET_NOTIFICATION_LIST_FAIL:
      return {
        ...state,
        error: error_handler_helper(action.payload),
        loading: true,
      }
    case GET_NOTIFICATION_DETAIL:
      return {
        ...state,
        notificationDetail: action.payload,
        loading: true,
      }
    case GET_NOTIFICATION_DETAIL_SUCCESS:
      return {
        ...state,
        notificationDetail: action.payload,
        loading: false,
      }
    case GET_NOTIFICATION_DETAIL_FAIL:
      return {
        ...state,
        error: error_handler_helper(action.payload),
        loading: false,
      }
    case SET_NOTIFICATION:
      return {
        ...state,
        notificationDetail: action.payload,
        loading: true,
      }
    case SET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationDetail: action.payload,
        loading: false,
        setSuccess: true,
      }
    case SET_NOTIFICATION_FAIL:
      return {
        ...state,
        error: error_handler_helper(action.payload),
        loading: false,
        setSuccess: false,
      }
    case UPDATE_NOTIFICATION:
      return {
        ...state,
        notificationDetail: action.payload,
        loading: true,
      }
    case UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationDetail: action.payload,
        loading: false,
        updateSuccess: true,
      }
    case UPDATE_NOTIFICATION_FAIL:
      return {
        ...state,
        error: error_handler_helper(action.payload),
        loading: false,
        updateSuccess: false,
      }
    case DELETE_NOTIFICATION:
      return {
        ...state,
        notificationDetail: action.payload,
        loading: true,
      }
    case DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationDetail: action.payload,
        loading: false,
        deleteSuccess: true,
      }
    case DELETE_NOTIFICATION_FAIL:
      return {
        ...state,
        error: error_handler_helper(action.payload),
        loading: false,
        deleteSuccess: false,
      }
    case RESTART_STATE_NOTIFICATIONS:
      return {
        ...state,
        // notificationList: [],
        // notificationDetail: {},
        error: {},
        loading: false,
        updateSuccess: undefined,
        setSuccess: undefined,
        deleteSuccess: undefined,
      }

    default:
      return state
  }
}

export default Notification
