/* MERCHANT DETAIL*/
export const GET_LICENSE_REQUEST_DETAIL = "GET_LICENSE_REQUEST_DETAIL"
export const GET_LICENSE_REQUEST_DETAIL_SUCCESS = "GET_LICENSE_REQUEST_DETAIL_SUCCESS"
export const GET_LICENSE_REQUEST_DETAIL_FAIL = "GET_LICENSE_REQUEST_DETAIL_FAIL"

export const GET_LICENSE_REQUEST_LIST = "GET_LICENSE_REQUEST_LIST"
export const GET_LICENSE_REQUEST_LIST_SUCCESS = "GET_LICENSE_REQUEST_LIST_SUCCESS"
export const GET_LICENSE_REQUEST_LIST_FAIL = "GET_LICENSE_REQUEST_LIST_FAIL"


export const GET_LICENSE_REQUEST_SUMMARY = "GET_LICENSE_REQUEST_SUMMARY"
export const GET_LICENSE_REQUEST_SUMMARY_SUCCESS = "GET_LICENSE_REQUEST_SUMMARY_SUCCESS"
export const GET_LICENSE_REQUEST_SUMMARY_FAIL = "GET_LICENSE_REQUEST_SUMMARY_FAIL"

export const UPDATE_LICENSE_REQUEST_DETAIL = "UPDATE_LICENSE_REQUEST_DETAIL"
export const UPDATE_LICENSE_REQUEST_DETAIL_SUCCESS = "UPDATE_LICENSE_REQUEST_DETAIL_SUCCESS"
export const UPDATE_LICENSE_REQUEST_DETAIL_FAIL = "UPDATE_LICENSE_REQUEST_DETAIL_FAIL"

export const RETURN_STATE = "RETURN_STATE"
