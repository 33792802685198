import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { isEmpty } from "lodash"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "./TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

// import {
//   getJobList as onGetJobList,
//   addNewJobList as onAddNewJobList,
//   updateJobList as onUpdateJobList,
//   deleteJobList as onDeleteJobList,
// } from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import {
  Grn,
  OfficeId,
  ProcessedDate,
  TransactionDate,
  TravelAgencyName,
  CustomerFirstName,
  CustomerLastName,
  Currency,
  Status,
  TotalAmount,
  StaffName,
  Commission,
  Nationality,
} from "./LicenseRequestColumn"
import { change } from "redux-form"

const LicenseRequest = props => {
  //meta title
  document.title = "International Driving Licenses | Amadeus Qatar"

  const {
    user,
    licenses,
    voucherLimits,
    onGetOfficeListByCustomerId,
    onGetCustomersList,
    onUpdateLicenseRequestDetail,
    onGetLicenseRequestList,
    customers,
    offices,
  } = props

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [jobsList, setJobsList] = useState([])
  const [license, setLicense] = useState(null)
  const [showOfficeSelection, setShowOfficeSelection] = useState(false)

  //validation
  const validation = useFormik({

    enableReinitialize: true,

    initialValues: {
      grn: (license && license.grn) || "",
      customer: {
        customerID: "",
        customerName: "",
      },
      office: {
        officeID: "",
        officeName: "",
      },
      customerId: (license && license.customerId) || "",
      travelAgencyName: (license && license.travelAgencyName) || "",
      officeId: (license && license.officeId) || "",
      officeName: (license && license.officeName) || "",
      staffName: (license && license.staffName) || "",
    },
    validationSchema: Yup.object({
      grn: Yup.string().required("Please Enter Grn"),
      customer: Yup.object().required("Please Select Customer "),
      office: Yup.object().required("Please Select Office"),
      customerId: Yup.string().required("Please Enter Customer Id"),
      travelAgencyName: Yup.string().required("Please Enter Your Agency Name"),
      officeId: Yup.string().required("Please Enter Office Id"),
      officeName: Yup.string().required("Please Enter OfficeName"),
      staffName: Yup.string().required("Please Enter Staff Name"),
    }),
    onSubmit:  values => {
      if (isEdit) {
        const updatedLicense = {
          _id: license ? license._id : 0,
          grn: license.grn,
          customer: values.customer,
          office: values.office,
          customerId: values.customerId,
          officeName: values.officeName,
          travelAgencyName: values.travelAgencyName,
          officeId: values.officeId,
          staffName: values.staffName,
          lastUpdateBy: user.email,
        }

       
        // update Job
        onUpdateLicenseRequestDetail(updatedLicense)
        validation.resetForm()
      } 
      toggle()
     handleReloadTable()
    },
  })

  const columns1A = useMemo(
    () => [
      {
        Header: "GRN",
        accessor: "grn",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return (
            <Link
              to="#"
              onClick={() => {
                const transactionData = cellProps.row.original
                handleOpenOverview(transactionData._id)
              }}
            >
              <Grn {...cellProps} />
            </Link>
          )
        },
      },
      {
        Header: "Transaction Date",
        accessor: "transactionDate",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <TransactionDate {...cellProps} />
        },
      },
      {
        Header: "Travel Agency",
        accessor: "travelAgencyName",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <TravelAgencyName {...cellProps} />
        },
      },
      {
        Header: "OfficeId",
        accessor: "officeId",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <OfficeId {...cellProps} />
        },
      },
      {
        Header: "First Name",
        accessor: "customerProfile.firstName",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <CustomerFirstName {...cellProps} />
        },
      },

      {
        Header: "Last Name",
        accessor: "customerProfile.lastName",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <CustomerLastName {...cellProps} />
        },
      },
      {
        Header: "Nationality",
        accessor: "customerProfile.drivingLicenseNationality",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Nationality {...cellProps} />
        },
      },
      {
        Header: "Staff Name",
        accessor: "staffName",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <StaffName {...cellProps} />
        },
      },
      {
        Header: "Total",
        accessor: "totalAmount",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <TotalAmount {...cellProps} />
        },
      },
      {
        Header: "1A",
        accessor: "invoiceDetails.services",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
         
          return <Commission {...cellProps} />
        },
      },

      {
        Header: "Currency",
        accessor: "currency",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Currency {...cellProps} />
        },
      },

      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        Cell: cellProps => {
          return <Status {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "_id",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-primary"
                  onClick={() => {
                    const transactionData = cellProps.row.original
                    handleOpenOverview(transactionData._id)
                  }}
                >
                  <i className="mdi mdi-eye-outline" id="viewtooltip"></i>
                </Link>
              </li>
              <UncontrolledTooltip placement="top" target="viewtooltip">
                View
              </UncontrolledTooltip>

              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-info"
                  onClick={() => {
                    handleLicenseEdit(cellProps.row.original)
                  }}
                >
                  <i className="mdi mdi-pencil-outline" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </li>

              {/* <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const jobData = cellProps.row.original
                    onClickDelete(jobData)
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li> */}
            </ul>
          )
        },
      },
    ],
    []
  )

  const columnsCustomer = useMemo(
    () => [
      {
        Header: "GRN",
        accessor: "grn",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return (
            <Link
              to="#"
              onClick={() => {
                const transactionData = cellProps.row.original
                handleOpenOverview(transactionData._id)
              }}
            >
              <Grn {...cellProps} />
            </Link>
          )
        },
      },
      {
        Header: "Transaction Date",
        accessor: "transactionDate",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <TransactionDate {...cellProps} />
        },
      },
      {
        Header: "Travel Agency",
        accessor: "travelAgencyName",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <TravelAgencyName {...cellProps} />
        },
      },
      {
        Header: "OfficeId",
        accessor: "officeId",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <OfficeId {...cellProps} />
        },
      },
      {
        Header: "First Name",
        accessor: "customerProfile.firstName",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <CustomerFirstName {...cellProps} />
        },
      },

      {
        Header: "Last Name",
        accessor: "customerProfile.lastName",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <CustomerLastName {...cellProps} />
        },
      },
      {
        Header: "Nationality",
        accessor: "customerProfile.drivingLicenseNationality",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Nationality {...cellProps} />
        },
      },
      {
        Header: "Staff Name",
        accessor: "staffName",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <StaffName {...cellProps} />
        },
      },
      {
        Header: "Total",
        accessor: "totalAmount",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <TotalAmount {...cellProps} />
        },
      },
      {
        Header: "Currency",
        accessor: "currency",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <Currency {...cellProps} />
        },
      },

      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        Cell: cellProps => {
          return <Status {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "_id",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-primary"
                  onClick={() => {
                    const transactionData = cellProps.row.original
                    handleOpenOverview(transactionData._id)
                  }}
                >
                  <i className="mdi mdi-eye-outline" id="viewtooltip"></i>
                </Link>
              </li>
              <UncontrolledTooltip placement="top" target="viewtooltip">
                View
              </UncontrolledTooltip>

             

              {/* <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const jobData = cellProps.row.original
                    onClickDelete(jobData)
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li> */}
            </ul>
          )
        },
      },
    ],
    []
  )

  const [columns, setColumns] = useState(columnsCustomer)

  useEffect(() => {
    if(user.customerID === null){
       setColumns(columns1A)
    }
  }, [user]);

  useEffect(() => {
    
  }, [columns1A]);
 

  useEffect(() => {
    if (!isEmpty(licenses) && !!isEdit) {
      setJobsList(licenses)
      setIsEdit(false)
    }
  }, [licenses])

  const toggle = () => {
    if (modal) {
      setModal(false)
      setLicense(null)
    } else {
      setModal(true)
    }
  }

  useEffect(() => {}, [showOfficeSelection])

  const handleReloadTable = async() => {
    //this is to reload the table
    await onGetLicenseRequestList()
  }

  const handleLicenseEdit = async arg => {


    const license = arg
    setLicense({
      _id: license._id,
      grn: license.grn,
      customer: license.customer,
      office: license.office,
      customerId: license.customerId,
      travelAgencyName: license.travelAgencyName,
      officeId: license.officeId,
      officeName: license.officeName,
      staffName: license.staffName,
    })

    setIsEdit(true)
    setShowOfficeSelection(false)
    await onGetCustomersList()

    toggle()
  }

  //delete Job
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = job => {
    setLicense(job)
    setDeleteModal(true)
  }

  const handleDeletejob = () => {
    if (license && license.id) {
      dispatch(onDeleteJobList(license.id))
      setDeleteModal(false)
    }
  }

  const handleOpenOverview = id => {
  
    const link = "/idl/licenses/" + id
    window.open(link, "_blank")
  }

  const handleGetOffices = async customer => {
  

    const newValues = {
      grn: license.grn,
      customer: {
        customerID: customer.customerID,
        customerName: customer.customerName,
      },
      office: validation.values.office,
      customerId: customer.customerID,
      travelAgencyName: customer.customerName,
      officeId: validation.values.officeId,
      officeName: validation.values.officeName,
      staffName: license.staffName,
    }

    validation.setValues(newValues)

    await onGetOfficeListByCustomerId(customer.customerID)
    setShowOfficeSelection(true)
  }

  const handleSelectOffice = office => {
   

    const newValues = {
      grn: license.grn,
      customer: validation.values.customer,
      office: office,
      customerId: validation.values.customerId,
      travelAgencyName: validation.values.travelAgencyName,
      officeId: office.officeID,
      officeName: office.officeName,
      staffName: license.staffName,
    }

    validation.setValues(newValues)
  }


  return (
    <>
      {/* <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeletejob}
                onCloseClick={() => setDeleteModal(false)}
            /> */}

      <Row>
        <Col lg="12">
          <TableContainer
            columns={columns}
            data={licenses}
            voucherLimits={voucherLimits}
            isGlobalFilter={true}
            isAddOptions={false}
            // handleJobClicks={handleJobClicks}
            isJobListGlobalFilter={true}
            customPageSize={50}
          />
        </Col>
      </Row>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">
          {!!isEdit ? "Edit Detail" : "Add Job"}
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Row>
              <Col className="col-12">
                <div className="mb-3">
                  <Label className="form-label"> GRN</Label>
                  <Input
                    readOnly
                    style={{ backgroundColor: "#f1f1f1" }}
                    name="grn"
                    type="text"
                    placeholder=""
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.grn || ""}
                    invalid={
                      validation.touched.grn && validation.errors.grn
                        ? true
                        : false
                    }
                  />
                  {validation.touched.grn && validation.errors.grn ? (
                    <FormFeedback type="invalid">
                      {validation.errors.grn}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">Select a customer :</Label>
                  <Input
                    name="customer"
                    type="select"
                    onBlur={e => validation.handleBlur(e)}
                    onChange={async e => {
                      const selectedCustomer = JSON.parse(e.target.value) // Parse the selected object
                      validation.handleChange(e)
                      await handleGetOffices(selectedCustomer)
                    }}
                    
                    value={JSON.stringify(validation.values.customer || "")} // Stringify the selected customer object
                   
                    invalid={
                      validation.touched.customer && validation.errors.customer
                        ? true
                        : false
                    }
                  >
                
                    <option value="" defaultValue hidden>
                      {!isEmpty(validation.values.customer.customerID)
                        ? `${validation.values.customer.customerID} - ${validation.values.customer.customerName}`
                        : "Select Customer"}
                    </option>
                    {customers &&
                      customers.map((customer, i) => (
                        <option key={i} value={JSON.stringify(customer)}>
                          {customer.customerID} {"-"}  {customer.customerName}
                        </option>
                      ))}
                  </Input>
                  {validation.touched.customer && validation.errors.customer ? (
                    <FormFeedback status="invalid">
                      {validation.errors.customer}
                    </FormFeedback>
                  ) : null}
                </div>
                {showOfficeSelection && (
                  <div className="mb-3">
                    <Label className="form-label">Select an office</Label>
                    <Input
                      name="office"
                      type="select"
                      onChange={e => {
                        const selectedOffice = JSON.parse(e.target.value) // Parse the selected object
                        validation.handleChange(e)
                        handleSelectOffice(selectedOffice)
                      }}
                     
                      value={JSON.stringify(validation.values.office || "")} 
                   
                      onBlur={e => validation.handleBlur(e)}
                      // value={validation.values.office || ""}
                      invalid={
                        validation.touched.office && validation.errors.office
                          ? true
                          : false
                      }
                    >
                     <option value="" defaultValue hidden>
                      {!isEmpty(validation.values.office.officeID)
                        ? `${validation.values.office.officeID} - ${validation.values.office.officeName}`
                        : "Select Office"}
                    </option>
                      {offices &&
                        offices.map((office, i) => (
                          <option
                            key={i}
                            onSelect={() => null}
                            value={JSON.stringify(office)}
                          >
                            {office.officeID} {"-"} {office.officeName}
                          </option>
                        ))}
                    </Input>
                    {validation.touched.office && validation.errors.office ? (
                      <FormFeedback status="invalid">
                        {validation.errors.office}
                      </FormFeedback>
                    ) : null}
                  </div>
                )}
                <hr style={{ border: "1px solid #f1f1f1" }}></hr>

                <div className="mb-3">
                  <Label className="form-label">customerId</Label>
                  <Input
                    readOnly
                    style={{ backgroundColor: "#f1f1f1" }}
                    name="customerId"
                    type="text"
                    placeholder=""
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.customerId || ""}
                    invalid={
                      validation.touched.customerId &&
                      validation.errors.customerId
                        ? true
                        : false
                    }
                  />
                  {validation.touched.customerId &&
                  validation.errors.customerId ? (
                    <FormFeedback type="invalid">
                      {validation.errors.customerId}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">Travel Agency</Label>
                  <Input
                    readOnly
                    style={{ backgroundColor: "#f1f1f1" }}
                    name="travelAgencyName"
                    type="text"
                    placeholder=""
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.travelAgencyName || ""}
                    invalid={
                      validation.touched.travelAgencyName &&
                      validation.errors.travelAgencyName
                        ? true
                        : false
                    }
                  />
                  {validation.touched.travelAgencyName &&
                  validation.errors.travelAgencyName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.travelAgencyName}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="mb-3">
                  <Label className="form-label">OfficeId</Label>
                  <Input
                    readOnly
                    style={{ backgroundColor: "#f1f1f1" }}
                    name="officeId"
                    type="text"
                    placeholder=""
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.officeId || ""}
                    invalid={
                      validation.touched.officeId && validation.errors.officeId
                        ? true
                        : false
                    }
                  />
                  {validation.touched.officeId && validation.errors.officeId ? (
                    <FormFeedback type="invalid">
                      {validation.errors.officeId}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="mb-3">
                  <Label className="form-label">Office Name</Label>
                  <Input
                    readOnly
                    style={{ backgroundColor: "#f1f1f1" }}
                    name="officeName"
                    type="text"
                    placeholder=""
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.officeName || ""}
                    invalid={
                      validation.touched.officeName &&
                      validation.errors.officeName
                        ? true
                        : false
                    }
                  />
                  {validation.touched.officeName &&
                  validation.errors.officeName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.officeName}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="mb-3">
                  <Label className="form-label">Staff Name</Label>
                  <Input
                    name="staffName"
                    placeholder=""
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.staffName || ""}
                    invalid={
                      validation.touched.staffName &&
                      validation.errors.staffName
                        ? true
                        : false
                    }
                  />
                  {validation.touched.staffName &&
                  validation.errors.staffName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.staffName}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default LicenseRequest
