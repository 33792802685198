import React from 'react';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import dateFormat from "../../common/helpers/dateFormat"


const Date = (cell) => {
    return cell.value ? <span>{dateFormat(cell.value)}</span> : "";
};

const LastUpdate = (cell) => {
    return cell.value ? <span className="text-muted">{dateFormat(cell.value)}</span> : "";
};

const BankReference = (cell) => {
    return cell.value ? cell.value : "";
};


const Amount = (cell) => {
    return cell.value ? cell.value : "";
};

const Currency = (cell) => {
    return cell.value ? cell.value : "";
};

const Limit = (cell) => {
    return cell.value ? cell.value : "";
};


const AddedBy = (cell) => {
    return cell.value ? cell.value : "";
};

const LastUpdateBy = (cell) => {
    return cell.value ? <span className="text-muted">{cell.value}</span> : "";
};

const CustomerId = (cell) => {
    return cell.value ? cell.value : "";
};

const CustomerName = (cell) => {
    return cell.value ? cell.value : "";
};


const IsActive = (cell) => {
    switch(cell.value) {
        case true:
            return <Badge className="bg-success">ACTIVE</Badge>
        case false:
            return <Badge className="bg-info">DELETED</Badge>
       
    }
};



export { Date, BankReference, Amount,Currency, Limit,  AddedBy, LastUpdate, LastUpdateBy, CustomerId, CustomerName, IsActive };